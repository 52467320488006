import React, { useEffect,useState } from "react"
import { Navigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { Content } from "../components/content/Content"
import { validateToken } from "../slices"

export const PrivateRoutes = ({ children }) => {
  // validar Login
  // eslint-disable-next-line
  const [token, setToken] = useState(localStorage.getItem('token'))
  const dispatch = useDispatch()

  const onValidateToken = async () => {
    if (document.visibilityState === 'visible') {
      dispatch(validateToken());
    }
  }
  
  useEffect( () => {
    setInterval( async () => {
      onValidateToken()
    }, 50000)
    // eslint-disable-next-line
  }, [])

  return (!!token) ? <>
    <Content >
      {children}
    </Content>
  </> : <Navigate to="/" />


}