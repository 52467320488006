import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { graficaAlerts as graficaAnidada} from "../../slices/bi/alertsThunks"
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsAccessibility from "highcharts/modules/accessibility";
import HighchartsStock from "highcharts/modules/stock";
import HighchartsData from "highcharts/modules/data";
import HighchartsExporting from "highcharts/modules/exporting";
import { useDispatch } from 'react-redux';

// Habilitar módulos de Highcharts
HighchartsAccessibility(Highcharts);
HighchartsStock(Highcharts);
HighchartsData(Highcharts);
HighchartsExporting(Highcharts);

// Opciones iniciales del gráfico
const option = {
  chart: {
    height: "30%",
    backgroundColor: "#FFFFFF",
    alignTicks: true,
    borderWidth: 2,
    borderColor: "#A50034",
    style: {
      fontFamily: "Arial Narrow",
      fontSize: "18px",
    },
  },
  title: {
    text: "",
  },
  subtitle: {},
  accessibility: {
    series: {
      descriptionFormat: "{seriesDescription}.",
    },
    description:
      "Use the dropdown menus above to display different indicator series on the chart.",
    screenReaderSection: {
      beforeChartFormat:
        "<{headingTagName}>{chartTitle}</{headingTagName}><div>{typeDescription}</div><div>{chartSubtitle}</div><div>{chartLongdesc}</div>",
    },
  },
  rangeSelector: {
    allButtonsEnabled: true, // selector de rango
    selected: 1,
    //verticalAlign: 'bottom'
  },
  scrollbar: {
    enabled: false, // habilita el scrollbar
    showFull: true, // mostrar todo el contenido del gráfico al principio
    liveRedraw: true, // redibujar el gráfico en tiempo real cuando se arrastra el scrollbar
  },
  navigator: {},
  xAxis: {},
  yAxis: [
    {
      title: {
        text: "",
      },
      height: "100%",
      crosshair: {
        width: 1,
        color: "red",
      },
    },
    {
      title: {
        text: "",
      },
      //height: "",
      crosshair: {
        width: 1,
        color: "red",
      },
      opposite: true,
    },
    {
      title: {
        text: " ",
      },
      top: "75%",
      height: "25%",
      offset: 0,
    },
  ],
  tooltip: {
    enabled: true,
    shared: true,
    borderRadius: 20,
    borderColor: "red",
  },
  series: [],
};

// Componente funcional de React para el gráfico combinado de alertas
export const GraficosCombinadosAlerts = () => {
  // state
  // dispatch
  // useEffect
  const dispatch = useDispatch(); 
  // Función para limpiar los datos antes de graficar
  const datosGraficas = (data) => {
    if (data && data.data.length > 0) {
      const valoresNumericos = data.data.map((obj) => {
        const numericProps = Object.keys(obj).filter(
          (prop) => prop !== "WEEK_1"
        )
        return numericProps.map((prop) => obj[prop]);
      })
      return valoresNumericos[0]
    }
    return [] // Devuelve un array vacío en lugar de null
  }
// Variables de estado de Redux
const loading = useSelector((state) => state.bi_wos.isLoading)
const { dealerAlerts, pgAlerts, weeks } = useSelector((state) => state.alerts)
const [options, setOptions] = useState(option)
const { pkam, sellInv, srf, sfcst, eightWF } = useSelector((state) => state.alerts.graficaAlerts)
const [arrayWeeks, setArrayWeeks] = useState(weeks)
const [valoresLimpios, setvaloresLimpios] = useState([])
const [valoresLimpiosSellInv, setvaloresLimpiosSellInv] = useState([])
const [valoresLimpiosSRF, setvaloresLimpiosSRF] = useState([])
const [valoresLimpiosSFCST, setvaloresLimpiosSFCST] = useState([])
const [valoresLimpios8WFBarras, setvaloresLimpios8WFBarras] = useState([])



useEffect(() => {    
  let dealers = {
    'DEALER': dealerAlerts.map(({value}) => value)
  }
  let pg = {
    'PG': pgAlerts.map(({value}) => value)
  }
  dispatch(graficaAnidada({ ...dealers, ...pg }))
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [dealerAlerts, pgAlerts])

   // Efecto para limpiar y establecer datos para graficar cuando cambian los datos
  useEffect(() => {
    const valoresLimpiosPkam = datosGraficas(pkam);
    const valuesSinWeekSellInv = datosGraficas(sellInv);
    const valuesSinWeekSRF = datosGraficas(srf);
    const valuesSinWeekSFCST = datosGraficas(sfcst);
    const valuesSinWeek8WF = datosGraficas(eightWF);
    setvaloresLimpios(valoresLimpiosPkam);
    setvaloresLimpiosSellInv(valuesSinWeekSellInv);
    setvaloresLimpiosSRF(valuesSinWeekSRF);
    setvaloresLimpiosSFCST(valuesSinWeekSFCST);
    setvaloresLimpios8WFBarras(valuesSinWeek8WF);
  }, [pkam, sellInv, srf, sfcst, eightWF]);

  // Efecto para actualizar el array de semanas cuando cambia la semana seleccionada
useEffect(() => {
    // Función para obtener el número de la semana actual
const obtenerNumeroSemanaActual = () => {
  return weeks.length > 0 && weeks[0].value ? parseInt(weeks[0].value.substring(1)) : 0;
}
  
  // Variables de lógica para la ubicación de los labels de las semanas
  let wosMenos = 5
  let wosMas = 11

  // Variables de lógica para el Mes
  let mesMenos1 = 2

  // Función para obtener el nombre del mes en inglés
  const obtenerNombreMes = (mes) => {
    const meses = [
      "JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
    ]
    return meses[mes]
  }
  // Obtener el mes actual (0-11)
  const mesActual = new Date().getMonth()

  // Tomar los valores de la semana actual y adicionar las 3 semanas anteriores y las 8 semanas siguientes en un nuevo array y añadir la 'W' a cada valor
  const numeroSemanaActual = obtenerNumeroSemanaActual()   // Extraer el número de la cadena
 
  const mesesAnteriores = Array.from({ length: mesMenos1 }, (_, i) => obtenerNombreMes((mesActual - 2 + 12 + i) % 12))
  const semanasSiguientes = Array.from({ length: wosMas + 1 }, (_, i) => {
    let weekNumber = ((numeroSemanaActual - wosMenos + i) % 52) + 2;
    if (weekNumber <= 0) {
      weekNumber += 52; // Ajustar el número de la semana si es menor o igual a 0
    }
    return `W${weekNumber.toString().padStart(2, "0")}`;
  });


  const numerosCombinadosConW = [...mesesAnteriores, ...semanasSiguientes]

  setArrayWeeks(numerosCombinadosConW)

}, [weeks])

  // Efecto para actualizar las opciones del gráfico cuando cambian datos relevantes
  useEffect(() => {   
    const primeraCondicion = valoresLimpiosSellInv && valoresLimpiosSellInv.length > 0
    const segundaCondicion = valoresLimpiosSellInv && valoresLimpiosSellInv.length === 1

    const seriesData = primeraCondicion
      ? valoresLimpiosSellInv.map((value, index) => ({
        y: value,
        color: index < 2 ? "rgb(255, 242, 207)" : "#bdd4ca",
        week: arrayWeeks[index],
      }))
      : [];
    const change = {
      navigator: {
        outlineColor: "black",
        opposite: false,
        maskInside: true,
        enabled: true,
        series: {
          type: "line",
          color: "red",
          fillOpacity: 0.03,
          lineWidth: 2,
          showInNavigator: true,
          maskFill: "black",
        },
        xAxis: {
          categories: arrayWeeks || [],
        },
      },
      xAxis: {
        categories: arrayWeeks || arrayWeeks[3] || [],
        crosshair: {
          width: 1,
          color: "#007fff",
        },
      },
      series: [
        {
          type: "column",
          data: segundaCondicion
            ? valoresLimpiosSellInv.slice(2).map((value, index) => ({
              y: value,
              week: arrayWeeks[index + 2], // Asegurarse de obtener la semana correcta
            }))
            : [],
          name: "Sellable Inv.",
          yAxis: 1,
          dataLabels: {
            enabled: true,
            formatter() {
              // Formatea el valor con coma como separador de miles
              return this.y.toLocaleString('es-ES');
            },
          },
          color: 'rgb(189, 212, 202)',
        },
        {
          type: "column",
          data: seriesData,
          name: "S Month [R]",
          yAxis: 1,
          offset: -5,
          dataLabels: {
            enabled: true,
            formatter() {
              // Formatea el valor con coma como separador de miles
              return this.y.toLocaleString('es-ES');
            },
          },
          color: 'rgb(255, 242, 204)',
        },

        {
          name: "WOS-4",
          data: valoresLimpios8WFBarras && valoresLimpios8WFBarras.length > 0
          ? valoresLimpios8WFBarras.slice(0, 6).map((value, index) => ({
              y: value,
              week: arrayWeeks[index],
            }))
          : [],
          marker: {
            enabled: true,
            radius: 3,
          },
          dashStyle: "solid",
          type: "spline",
          zones: [
            {
              value: 0,
              color: "blue",
            },
            {
              value: 8.7,
              color: "red",
            },
          ],
          dataLabels: {
            enabled: true,
            format: '{y}',
            shape: 'callout',
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            borderWidth: 1,
            borderColor: 'red',
            color: 'black', // Color de los datalabels
            formatter() {
              // Formatea el valor con coma como separador de miles
              return this.y.toLocaleString('es-ES');
            },
          },
          annotations: [
            {
              labels: [
                {
                  point: "max",
                  text: "Max",
                },
                {
                  point: "min",
                  text: "Min",
                  backgroundColor: "green",
                },
              ],
            },
          ],
          color:'red'
        },
  
        // Serie para las demás posiciones (WOS+8)
        {
          name: "WOS+8",
          data: valoresLimpios8WFBarras && valoresLimpios8WFBarras.length > 6
          ? valoresLimpios8WFBarras.slice(-14).map((value, index) => ({
              y: value,
              week: arrayWeeks[index + 6], // Asegurarse de obtener la semana correcta
            }))
          : [],
          marker: {
            enabled: true,
            radius: 3,
          },
          dashStyle: "solid",
          type: "spline",
          zones: [
            {
              value: 0,
              color: "blue",
            },
            {
              value: 8.7,
              color: "orange", // Cambiado a naranja para las posiciones restantes
            },
          ],
          dataLabels: {
            enabled: true,
            format: '{y}',
            shape: 'callout',
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            borderWidth: 1,
            borderColor: 'orange',
            color: 'black', // Color de los datalabels
            formatter() {
              // Formatea el valor con coma como separador de miles
              return this.y.toLocaleString('es-ES');
            },
          },
          annotations: [
            {
              labels: [
                {
                  point: "max",
                  text: "Max",
                },
                {
                  point: "min",
                  text: "Min",
                  backgroundColor: "green",
                },
              ],
            },
          ],
          color:'orange'
        },  
       
        {
          name: "P [KAM]",
          data: [null,null,...valoresLimpios],
          dashStyle: "shortdot",
          type: "spline",
          color: "#cc9900",
          annotations: [
            {
              labels: [
                {
                  point: "max",
                  text: "Max",
                },
                {
                  point: "min",
                  text: "Min",
                  backgroundColor: "green",
                },
              ],
            },
          ],
        },
        
 
        {
          name: "S [R+F]",
          data: [null,null,...valoresLimpiosSRF],
          marker: {
            enabled: true,
            radius: 3,
          },
          dashStyle: "solid", //"longdash",
          type: "spline",
          annotations: [
            {
              labels: [
                {
                  point: "max",
                  text: "Max",
                },
                {
                  point: "min",
                  text: "Min",
                  backgroundColor: "green",
                },
              ],
            },
          ],
          color: 'green'
        },
        {
          name: "S FCST",
          data: [null,null,...valoresLimpiosSFCST],
          marker: {
            enabled: true,
            radius: 3,
          },
          dashStyle: "solid", //"longdash",
          type: "spline",

          color: "#F0ECE4",
          annotations: [
            {
              labels: [
                {
                  point: "max",
                  text: "Max",
                },
                {
                  point: "min",
                  text: "Min",
                  backgroundColor: "green",
                },
              ],
            },
          ],
        },
      ],
    };

    setOptions({
      ...options,
      ...change,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pkam, sellInv, srf, valoresLimpios8WFBarras]);

  return loading ? (
      <div className="bg-white my-2">
        <HighchartsReact
          filterstabsGrafComb={""}
          highcharts={Highcharts}
          options={options}
        />
      </div>
  ) : (
    ""
  );
};
