import { instanceAxios, instanceAxiosPrivate } from "../../axios"
import { changeLogged, resetState, setContacs, setIsUserIdentified, setPerfil, setToken,  setUsersGeneral } from "./userSlice"
import { toast } from "react-toastify";
import { resetMenu } from "../navigation/navigationSlice";

export const loggedUsers = (users = {}) => {
  return async (dispatch) => {
      const { data } = await instanceAxios.post('/v1/login', {
        ...users
      })
      // eslint-disable-next-line
      const { isSuppliers = false , isUserIdentified = false , message = '', user_id = 0 } = data;
      // Asigna de manera General.
      dispatch(setUsersGeneral(data))
      toast.success(message)      
  }
}

export const validateToken = () => async (dispatch) => {
  try {
    const xhr = new instanceAxiosPrivate()
    const data = await xhr.services.get('v1/renewToken')    
    const { token = '' } = data.data;    
    xhr.setToken(token);
    dispatch(setToken(token));  
  } catch ({response}) {     
      const { mns = 'Inconsistencia en los datos, ingrese nuevamente' } = response?.data;
      toast.error(mns);   
      setTimeout(() => {        
        dispatch(logout());
      }, 2000);
    }    
}

export const logout = () => async (dispatch) => {  
  const xhr = new instanceAxiosPrivate()
  xhr.removeToken();
  dispatch(setToken(''))
  dispatch(resetMenu())
  dispatch(resetState())
  window.location.replace('/');
}

export const allContacts = () => async (dispatch) => {
  const xhr = new instanceAxiosPrivate()
  const { data } = await xhr.services.get('v1/users')
  dispatch(setContacs(data))
}


export const infoUserAuthenticated = () => async (dispatch) => {
  try {
    const xhr = new instanceAxiosPrivate()
    const { data } = await xhr.services.get('v1/perfil')
    dispatch(setPerfil(data));    
  } catch ({response}) {      
    const { mns = 'Inconsistencia en los datos' } = response?.data;
    toast.error(mns);            
    setTimeout(() => {        
      dispatch(logout());      
    }, 3000);         
      
  }
}

export const validateSignInUsers = (users = {}) => {
  return async (dispatch) => {
      
      const { data } = await instanceAxios.post('/v1/validateLoginUserslG', {
        ...users
      })
      
      // El usuario Existe Se solicita Otp + Password.

      // assign Token the Store.
      localStorage.setItem('token',data.token)
      dispatch(setToken(data.token))
      // logged ok
      dispatch(changeLogged(true))
  }
}

export const validateSignSuppliers = (login = {}) => {
  return async (dispatch) => {
      const { data } = await instanceAxios.post('/v1/validateLoginSuppliers', {
        ...login
      })
      const { token = ''} = data
      const instAxiosPrivate = new instanceAxiosPrivate()
      instAxiosPrivate.setToken(token)
      dispatch(setToken(token))
      dispatch(changeLogged(true))
      dispatch(setIsUserIdentified(true))
      
  }
}