export const formatCurrency = (amount) => {
    let currencyReplaced = false;
    return new Intl.NumberFormat('es-CO', { style: "currency", currency: 'COP', minimumFractionDigits: 2 })
      .formatToParts(amount)
      .map((item, idx, arr) => {
        if ((item.type === "currency" || item.type === "literal") && currencyReplaced ) return "";
        const nextCurrency =
          arr[idx + 1] && arr[idx + 1].type === "currency" && arr[idx + 1].value;
        if (item.type === "minusSign" && nextCurrency && !currencyReplaced) {
          currencyReplaced = true;
          return `${nextCurrency} ${item.value}`;
        }
        return `${item.value}`;
      })
      .join("");
  };