import axios from "axios"

// use endpoint Public
export const instanceAxios = axios.create({
    baseURL: `${process.env.REACT_APP_ENDPOINT}/`
})


export const existToken = () => {
    if (localStorage.getItem('token') === null && localStorage.getItem('token') === undefined) {
        console.log('NO existe Token');
        return;        
    }    
    return localStorage.getItem('token')
}

// use endpoint Private
export const instanceAxiosPrivate3 = axios.create({
    baseURL: `${process.env.REACT_APP_ENDPOINT}/`,
    headers: {'x-token-lg': existToken()}
})


// use endpoint Private
export const instanceAxiosPrivate = (() => {
    
    function instanceAxiosPrivate(){
        this.token = localStorage.getItem('token')
        this.baseURL = `${process.env.REACT_APP_ENDPOINT}`
        this.services = axios.create({
            baseURL: `${process.env.REACT_APP_ENDPOINT}/`,
            headers: this.token ? {'x-token-lg': this.token } : {}
        })
    }

    instanceAxiosPrivate.prototype.getToken = function() {
        return this.token
    }
    
    instanceAxiosPrivate.prototype.setToken = (strToken = '') => {
        localStorage.setItem('token',strToken)
        instanceAxiosPrivate.prototype.token = strToken
    }

    //delete token
    instanceAxiosPrivate.prototype.removeToken = () => {
        localStorage.removeItem('token')
    }
    
    return instanceAxiosPrivate
})()

