import { instanceAxiosPrivate } from "../../axios";
import {
  setWeeks,
  setInvDistr,
  setChannelAlerts,
  setDealerAlerts,
  setPgAlerts,
  setTablaAlerts1,
  setTablaPg2,
  setTablaAlerts2,
  setfiltroAlertsBI,
  setfiltroGraficaAlerts,
  setfiltrosAlerts,
  setTableAlersInd,
  setTableAlersModel
} from "./alertsSlice";
const xhr = new instanceAxiosPrivate()

export const alerts_Filters = () => {
  return async (dispatch, getState) => {
    // * Get Channel
    try {
      const { permissionKams = {}} = getState().bi_wos
      const {
        DEALER = [],
        // eslint-disable-next-line no-unused-vars
        CHANNEL = [],
        // eslint-disable-next-line no-unused-vars
        PG = [],
        // eslint-disable-next-line no-unused-vars
        REGIONAL = []
      } = permissionKams
    
      const { data: responseChannel } = await xhr.services.get(
        "/v1/bi/getChannelAlertsBI",
        {
          params: {
            CHANNEL: "HYPER",
          },
        }
      );
      const { data: channel } = responseChannel;
      // Asignar weeks al Slice -> Store
      dispatch(setChannelAlerts(channel));
      // * Get Dealers
      const { data: responseDealers } = await xhr.services.get(
        "/v1/bi/getDealer",
        {
          params: {
            CHANNEL: "HYPER",
            DEALER,
          },
        }
      );
      const { data: dealers } = responseDealers;
      // Asignar weeks al Slice -> Store
      dispatch(setDealerAlerts(dealers));

      // * get PG
      const { data: responsePg } = await xhr.services.get(
        "/v1/bi/getPG",
        {
          params: {
            CHANNEL: "HYPER",
            PG,
          },
        }
      );
      const { data: pg } = responsePg;
      dispatch(setPgAlerts(pg));
      
      // * Get Weeks
      const { data: responseWeeks } = await xhr.services.get(
        "/v1/bi/getWeeksAlerts",
        {
          params: {}
        }
      )
      const { data: weeks } = responseWeeks;
      dispatch(setWeeks(weeks))
   
      // * Get Inventory Distribution
      const { data: responseInvDistr } = await xhr.services.get(
        "/v1/bi/getInvDistAlerts",
        {
          params: {
            // STATUS: filterPG_2,
          },
        }
      );
      const { data: invDistr } = responseInvDistr;
   
      // Asignar weeks al Slice -> Store
      dispatch(setInvDistr(invDistr));
  
    } catch (error) {
      // Manejo de errores en caso de que la petición falle
      // console.error("Error en linea 98 al obtener Inventory Distribution en Alerts: ", error);
    }
  };
};

// * Tabla Alerts 1
export const tablaAlerts1 = (e) => {
  return async (dispatch) => {
    try {
      
      const { data } = await xhr.services.post(`/v1/bi/getTableAlerts1`,
        { ...e }
      )
      // totales tabla 1
      const { data:totaltbl1 } = await xhr.services.post(`/v1/bi/getTablaAlerts1Total`,
        {...e }
      )
      
      dispatch(setTablaAlerts1([
        ...data.data,
        totaltbl1
      ]))
      // Asignar Al Store
       //dispatch(setTablaAlerts1(data));
    } catch (error) {
      // Manejo de errores en caso de que la petición falle
      console.error(
        "Error en linea 144 al obtener los datos de la tabla Alerts: ",
        error
      );
    }
  };
};
// * Filtro de Alertas BI
export const alerts_FiltersBI = (e) => {
  return async (dispatch) => {
    try {
      const { data: responsefiltroBI } = await xhr.services.get(
        "/v1/bi/getDatosFiltroAlerts",
        {
          params: {
            ...e,
          },
        }
      )
      // Asignar weeks al Slice -> Store
      dispatch(setfiltroAlertsBI(responsefiltroBI))
    } catch (error) {
      // Manejo de errores en caso de que la petición falle
      console.error(
        "Error en filtro 338 al obtener los valores del filtros Alertas BI: ",
        error
      );
    }
  };
};

// * servicio de graficos generales
export const graficaAlerts = (e) => {
  return async (dispatch) => {
         // parametros que toma cuando e este vacio
     const defaultParams = {
     ...e,
    }
    const { data: responsePKAM } = await xhr.services.post(
      `v1/bi/getDatosGraficaAlerts`,
      {
        ...defaultParams,
      }
    );

    const { data: responseSellInv } = await xhr.services.post(
      `v1/bi/getGrafAlertsSellInv`,
      {
        ...defaultParams,
      }
    );

    const { data: responseSRF } = await xhr.services.post(
      `v1/bi/getGrafAlertsSRF`,
      {
        ...defaultParams,
      }
    );

    const { data: responseS_FCST } = await xhr.services.post(
      `v1/bi/getGrafAlertsS_FCST`,
      {
        ...defaultParams,
      }
    );

    const { data: response8WeeksForward } = await xhr.services.post(
      `v1/bi/getGrafAlerts8WeekForward`,
      {
        ...defaultParams,
      }
    );
    const general = {
      pkam: responsePKAM,
      sellInv: responseSellInv,
      srf: responseSRF,
      sfcst: responseS_FCST,
      eightWF: response8WeeksForward,
      isLoading: true,
    }
     // * Get Weeks
     const { data: responseWeeks } = await xhr.services.get(
      "/v1/bi/getWeeksAlerts",
      {
        params: {}
      }
    )
    const { data: weeks } = responseWeeks;
    dispatch(setWeeks(weeks))
 

    // Asignar Filtros
    dispatch(setfiltrosAlerts(e));
    // Asignar Al Store
    // dispatch(setfiltroGraficaPKAM(general))
    dispatch(setfiltroGraficaAlerts(general));
    // dispatch(setfiltroGraficaSellableInv(dataSInv))
   
  }
}
// * Tabla Alerts 2
export const tablaAlerts2 = (e) => {

  return async(dispatch) => {
    try {
      // * Número de la semana actual
      //const numeroSemanaActual = getISOWeek(new Date());
      //const semActualMenos2 = "W" + numeroSemanaActual;

      // const { dealerAlerts = {} } = getState().alerts;
      // const { DEALER = [] } = dealerAlerts;
      const defaultParams = {
        //WEEK_1: 'W46',
        ...e,
      };
      //default params mas los params que se seleccionen
    
      const { data } = await xhr.services.post(`/v1/bi/getTableAlerts2`,
        { ...defaultParams }
      )
      const { data:totaltbl2 } = await xhr.services.post(`/v1/bi/getTablaAlerts2Result`,
      { ...defaultParams }
    )
        
       // Agrupar resultados por PG o categoría
       const groupedData = data.reduce((acc, item) => {
        const pgKey = item['PG'];
        acc[pgKey] = [...(acc[pgKey] || []), item];
        return acc;
      }, {})

       // Agregar los totales al final de cada grupo sin duplicar toda la data
       const dataWithTotals = Object.entries(groupedData).flatMap(([key, group]) => {
         if (key !== 'undefined') {
           const totalForGroup = totaltbl2.find((total) => total.PG === key);
           return totalForGroup ? [...group, totalForGroup] : group;
         }
         return group; // Evitar agregar totales a la entrada 'undefined'
       });
 
         // Enviar a través de dispatch
         dispatch(setTablaAlerts2(dataWithTotals));
      
    } catch (error) {
      // Manejo de errores en caso de que la petición falle
      console.error(
        "Error en linea 144 al obtener los datos de la tabla Alerts: ",
        error
      )
    }
  };

  
}

export const tablaAlertsInd = (index, filtros = {}) => {
  return async(dispatch, getState) => {
    try {      
      const { data } = await xhr.services.post(`/v1/bi/getTableAlerts`,
        { ...filtros }
      )      
      let alert = getState().alerts.tablaAlerts2[index] || {}
      alert = {        
        // eslint-disable-next-line no-useless-computed-key
        ['TOTAL']: "TOTAL",
        ...alert,
        // eslint-disable-next-line no-useless-computed-key
        ['PG']: undefined,
        // eslint-disable-next-line no-useless-computed-key
        ['COSTUMER']: undefined,        
      }
      dispatch(setTableAlersInd({
        ...getState().alerts.tableAlersInd || {},
        [index] : [...data, alert], 
      }))
    } catch (error) {
      // window.toastr.error(error.message || error.stack || 'Error no Identificado' )
    }
  }
}
export const ontablaAlertsModel = (index, params) => {
  return async(dispatch, getState) => {
    try {      
      const { data } = await xhr.services.post(`/v1/bi/getTableAlertsModel2`,
        { 
          ...params 
        }
      )      
      dispatch(setTableAlersModel({
        ...getState().alerts.tablaAlertsModel || {},
        [index]: data
      }))            
    } catch (error) { // window.toastr.error(error.message || error.stack || 'Error no Identificado' 
    }}
} 

// * Tabla PG2
export const tablaPg2 = (e) => {
  return async (dispatch) => {
    try {
      //default params mas los params que se seleccionen
      const params = {
        //...defaultParams,
        ...e,
      };
      const { data } = await xhr.services.post(`/v1/bi/getTablaAlertPg2`,
        { ...params }
      )
         // totales tabla Pg2
         const { data:totaltblPg2 } = await xhr.services.post(`/v1/bi/getTablaAlertPg2Total`,
         { ...params }
       )
            
      dispatch(setTablaPg2([
        ...data.data,
        totaltblPg2
      ]))
 
    } catch (error) {
      // Manejo de errores en caso de que la petición falle
      console.error(
        "Error en linea 144 al obtener los datos de la tabla Pg2: ",
        error
      );
    }
  };
};