import React from 'react';
import { Route, Routes } from 'react-router-dom'
import { Contact } from '../Contacts'

export const UserRoutes = () => {
  
  const NotFound = () => {
    return (
      <>
        <p>403</p>
      </>
    )
  }
  return (
    <Routes>
      <Route path='user'>
        <Route path="contact" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  )
}