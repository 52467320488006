import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { infoUserAuthenticated } from "../../slices";

export const UserPanel = ({ imageSrc, imageAlt, link })=> {
  const dispatch = useDispatch()
  const { first_name, last_name } = useSelector( state => state.user.perfil)

  useEffect(() => {
    dispatch(infoUserAuthenticated())
  },[])

  return (
    <div 
      className="user-panel mt-3 pb-3 mb-3 d-flex"
    >
      <div className="image">
        <img src={imageSrc} className="img-circle elevation-2" alt={imageAlt} />
      </div>
      <div className="info">
        <a href={link}
         className="d-block"
         >
          {first_name}
        </a>
      </div>
    </div>
  )
}


  

  UserPanel.prototype = {
     imageSrc: PropTypes.string.isRequired,
     imageAlt: PropTypes.string.isRequired,
     name: PropTypes.string.isRequired,
     link: PropTypes.string.isRequired
   }

   UserPanel.defaultProps = {
     imageSrc: `${process.env.REACT_APP_HOST}/dist/img/user2-160x160.jpg`,
     imageAlt: 'User Image',
     name :"Sin Definir",
     link: '#'
   }
  
  
  
  
  
  