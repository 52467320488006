import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { pagosProveedor, pagosporfechaProveedor, facturasporfechaProveedor, detallesFacturaPagos } from '../../../slices/pagos/pagosThunks';
import Modal from '../../modal/Modal';
import { PaymentCard } from '../../Cards/PaymentCard';
import { Loading } from '../../loadings/Loading';
import { CardInvoice } from '../../Cards/CardInvoice';
import { resetdetallesDB } from '../../../slices/pagos/pagosSlice';
import { PaymentTableRow } from '../../PaymentTable/PaymentTableRow';


const Pagos = () => {
  const [isOpen, setIsOpen] = useState(false);

  const [expandedDetails, setExpandedDetails] = useState({});
  const [payment, setPayment] = useState('');

  const dispatch = useDispatch();
  const {  
    facturasDB, 
    pagosProveedorDB,
    facturasDetails,
    loading
  } = useSelector((state) => state.pagos);
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    dispatch(pagosporfechaProveedor(data))
  }

  const getInvoice = async (value) => {
    setPayment(value.payment_amount)
    let data = {
      payment_num: value.payment_num
    } 
    dispatch(facturasporfechaProveedor(data))
    setIsOpen(true);
  }
  const getDetails = async (value, index) => { 
    dispatch(detallesFacturaPagos(value.invoice, index));
    setExpandedDetails({
      ...expandedDetails,
      [index]: !expandedDetails[index],
    });
    
  }
  const onClearForm =  () => {
    dispatch(pagosProveedor());
    reset();
  }
  const modalHide = () => {
    setIsOpen(false);
    dispatch(resetdetallesDB())

  }
  useEffect(() => {
    dispatch(pagosProveedor());
    // eslint-disable-next-line
  },[]);

  return (
    <>
      <Modal 
        handleClose={modalHide} 
        isOpen={isOpen} 
        header='FACTURAS' 
        invoiceNum='' 
      >
        <div className="row overflow-auto" >
          { loading  ? (
            <>
              {
                facturasDB.length > 0 
                ? facturasDB?.map((r, index) =>(               
                    <div className="col-12  col-xl-12" key={index} >
                    <CardInvoice item={r} getDetails={()=>getDetails(r, index)}  getPayment={()=>{}} valuepayment={payment} />                    
                    {expandedDetails[index] && facturasDetails[r.invoice] && (
                      <>
                      <PaymentTableRow invoice={r.invoice} data={facturasDetails}/>
                      </>
                    )}
                    </div> 
                  )
                ) : (
                  <p>                          
                  No hay datos
                  </p>
                )
              }
            </>
          ) : (
            <Loading  text="Cargando ..." /> 
          )          
          }          
        </div>
      </Modal>
        <div className='row'>
            <div className='col-12'>
            <div className="row my-2">
              <div className="col-sm-6">
                <h1 className="m-0">Pagos</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">Proveedores</li>
                  <li className="breadcrumb-item active">Pagos</li>
                </ol>
              </div>
            </div>
              <div className='mt-2'>
                <form className='row col-12 col-md-12' onSubmit={handleSubmit(onSubmit)}>
                  <div className='col-12 col-md-3'>
                    <div className="form-group">
                      <label>Fecha inicial</label>
                      <input 
                        type="date"  
                        className='form-control'  
                        {...register("initial_date")}
                      />
                      <p>{errors.initial_date?.message}</p>
                    </div>
                  </div>
                  <div className='col-12 col-md-3'>
                    <div className="form-group">
                      <label>Fecha final</label>
                      <input 
                        type="date"  
                        className='form-control'  
                        {...register("end_date")}
                      />
                      <p>{errors.end_date?.message}</p>
                    </div>
                  </div>               
                  <div className='col-12 col-md-2 pt-4'>
                    <button 
                      type="submit" 
                      className='btn btn-danger btn-block font-weight-bold mt-2'>
                      Buscar
                    </button>
                  </div>
                  <div className='col-12 col-md-2 pt-4'>
                    <button 
                      type="button" 
                      className='btn btn-secondary btn-block font-weight-bold mt-2'
                      onClick={onClearForm}
                    >
                      Limpiar
                    </button>
                  </div>
                </form>
              </div>
              <hr />
              <div className='mt-4 px-2'>    
                <div className="row">
                    {
                      loading ? ( 
                      <>
                        {
                          pagosProveedorDB.length > 0 
                          ? pagosProveedorDB?.map((r, index) => 
                            <div className="col-12 col-lg-6 col-xl-2" key={index}>
                                <PaymentCard item={r} getInvoice={getInvoice}/>
                            </div> 
                          ) : (
                            <p>                          
                              No hay datos
                            </p>
                          ) }
                       </>  
                      ) : (
                        <Loading  text="Cargando ..." /> 
                      )                      
                    }
                </div>
              </div>
            </div>
        </div>
    </>
  )
}

export default Pagos;