/* eslint-disable no-useless-computed-key */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { tablaAlertsInd, tablaAlerts2 as tableSlice } from "../../../../slices/bi/alertsThunks";
import { rowsAsignColor } from '../../../../utils/rowsAsignColor';
import { TableDescription } from '../TableDescription';

//Main display
export const TableAlerts2 = () => {
  const { tablaAlerts2, dealerAlerts, filtrosAlerts, pgAlerts } = useSelector((state) => state.alerts);
  const dispatch = useDispatch();
  // Estado local para controlar la expansión de filas
  const [expandedRows, setExpandedRows] = useState({});
  const clavesExceptoWeek1 = tablaAlerts2?.length
    ? Object.keys(tablaAlerts2[0]).filter((key) => key !== "week_1")
    : [];

  // Función para alternar la expansión de una fila
  const toggleRowExpansion = (index, rowsdata) => {
    const params = {
      ...filtrosAlerts,
      PG: rowsdata.PG,
      DEALER: rowsdata.COSTUMER,
    };
    dispatch(tablaAlertsInd(index, params));
    setExpandedRows({
      ...expandedRows,
      [index]: !expandedRows[index],
    });
  };

  useEffect(() => {
    let dealers = {
      'DEALER': dealerAlerts.map(({ value }) => value)
    }
    let pg = {
      'PG': pgAlerts.map(({ value }) => value)
    }
    dispatch(tableSlice({ ...dealers, ...pg }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealerAlerts, pgAlerts])

  return (
    <div className="row table-wrapper">
      <div className="col-12">
        {tablaAlerts2 && (
          <table
            id="tbl_cpsi"
            className="table table-bordered table-hover my-table alineacion tamanioLetra"
            style={{fontFamily: "Arial Narrow" }}
          >
            <thead>
              <tr>
                {clavesExceptoWeek1.map((column, index) => (
                  <th key={index} className="titulos" style={{ whiteSpace: 'pre', wordWrap: 'break-word', lineHeight: '1.2', verticalAlign: 'middle', fontFamily: "Arial Narrow" }}>
                    {column}
                  </th>
                ))}
              </tr>
            </thead>
            {
            <tbody>
            {tablaAlerts2?.map((rowData, index) => (
              <React.Fragment key={index}>
                <tr
                  onClick={() => {
                    const element = tablaAlerts2.filter((item, indice) => indice === index).map(r => r)
                    const elementTotal = element.find((element) => element.COSTUMER === 'TOTAL')
          
                    if (!elementTotal) {
                      toggleRowExpansion(index, rowData)
                    }
                  }}
                  style={rowData.COSTUMER === 'TOTAL' ? {
                    fontWeight: 'bold', cursor: "pointer", whiteSpace: 'pre', wordWrap: 'break-word', lineHeight: '1.2', verticalAlign: 'middle',fontFamily: "Arial Narrow"
                  } : { cursor: "pointer", whiteSpace: 'pre', wordWrap: 'break-word', lineHeight: '1.2', verticalAlign: 'middle',fontFamily: "Arial Narrow" }}
          
                >
                  {clavesExceptoWeek1.map((column, colIndex) => (
                    <td style={rowsAsignColor(column, rowData[column])} key={colIndex}>
                      {rowData[column] === "0" || rowData[column] === null  ? '-' : 
                        (column === 'WOS -4\n|QTY|' || column === 'WOS +8\n|QTY|') ? 
                        parseFloat(rowData[column]).toFixed(1) : 
                        (rowData[column] < 0 ? rowData[column].toString().replace('-', '') : rowData[column])
                      }
                    </td>
                  ))}
                </tr>
                {expandedRows[index] && (
                  <tr>
                    <td colSpan={clavesExceptoWeek1.length}>
                      <TableDescription index={index} />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
            }
          </table>
        )}
      </div>
    </div>
  );
};
