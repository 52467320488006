import React from "react";
import {UserPanel} from "../user/UsuarioPanelAside";
import {Navigation} from "./MenuPanelNav";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../slices";
export const  Aside = () => {
  const  dispatch = useDispatch()
  

  const navigate = useNavigate()
  const onlogout = () => {
    dispatch(logout())
    navigate('/')
  }

  return (
    <aside className="main-sidebar sidebar-dark-danger elevation-4 d-flex flex-column">
      {/* Brand Logo */}
      <span className="brand-link"  target="blank">
        <img
          src={`${process.env.REACT_APP_HOST}/dist/img/Lg-logo.png`}
          alt="LG Logo"
          className="brand-image img-circle elevation-3"
          style={{ opacity: ".8" }}
        />
        <span className="brand-text font-weight-light">LG G2</span>
      </span>
      {/* Sidebar */}
      <div className="sidebar">
        <UserPanel />

        <Navigation/>
                
        {/* Logout */}
        <div className="my-3">
          <button 
              className="btn btn-danger btn-block" 
              type="button" 
              data-widget="navbar-search"
              onClick={onlogout}
            >
              Salir <i className="fas fa-sign-out-alt"></i>
            </button>
        </div>
      </div>      
    </aside>
  )
}
