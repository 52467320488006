import React from 'react'
import { isRouteErrorResponse, useRouteError } from 'react-router';

const ErrorBoundary = () => {
    const error = useRouteError();

    if (isRouteErrorResponse(error)) {
        if (error.status === 404) {
          return <div>Esta pagina no existe!</div>;
        }
    
        if (error.status === 401) {
          return <div>No estas autorizado a ver esto</div>;
        }

        if (error.status === 403) {
          return <div>Algo salio mal refresca la pestaña</div>;
        }
    
        if (error.status === 503) {
          return <div>Parece que nuestra API no funciona</div>;
        }
    
        if (error.status === 418) {
          return <div>🫖</div>;
        }
      }
  
    return <div>Algo salió mal</div>;  
}

export default ErrorBoundary