/* eslint-disable no-useless-computed-key */
import { existToken, instanceAxiosPrivate } from "../../axios"
import { toast } from "react-toastify";
import {
  changeLoading,
  changingLoadingAllDealers,
  setChannel,
  setDealer,
  setPg,
  setPg_Tool,
  setMajorCostmr,
  setChannelpsi,
  setFilterWos,
  setMeasureGeneral,
  setTableCPSIGral,
  setTableNewCPSI,
  setTableNewMeasure,
  setTableCpsiFilterDealer,
  setStateGeneral,
  setPermissionKams,
  setTableCpsiWeeks,
  setTableCpsiDealerWeeks
} from "./wosSlice"

const xhr = new instanceAxiosPrivate()

const createFilter = (data = []) => {
  const result = data.map((e) => {
    return e.value
  })
  return result
}

export const startChannel = () => {
  return async (dispatch) => {
    // Assign Channel
    try {
      if(!existToken()){return;}
      const { data: filterkams } = await xhr.services.get(
        `/v1/bi/permissionKams`
      )
      let { CHANNEL = [], DEALER = [], PG = [], REGIONAL = [] } = filterkams

      //limpiamos valores
      DEALER = DEALER.filter(
        (value, index, array) => array.indexOf(value) === index
      )
      CHANNEL = CHANNEL.filter(
        (value, index, array) => array.indexOf(value) === index
      )
      PG = PG.filter((value, index, array) => array.indexOf(value) === index)
      REGIONAL = REGIONAL.filter(
        (value, index, array) => array.indexOf(value) === index
      )

      dispatch(
        setPermissionKams({
          CHANNEL,
          DEALER,
          PG,
          REGIONAL,
        })
      )

      // * Get Major Costumer BI
      const { data: responseMajorCostmr } = await xhr.services.get(
        `/v1/bi/getMajorCostumer`,
        {}
      )
      const { data: majorCostmrBI } = responseMajorCostmr
      // Asignar AR al Slice -> Store
      dispatch(setMajorCostmr(majorCostmrBI))

      // * Get Channel
      const { data } = await xhr.services.get(`/v1/bi/getChannel`, {
        params: {
          CHANNEL,
        },
      })
      const { data: allChannel } = data
      const channels = createFilter(allChannel)
      dispatch(setChannel(allChannel))

      // * Get Dealer
      const { data: dealer } = await xhr.services.get(
        `/v1/bi/getDealer`,
        {
          params: {
            CHANNEL: channels,
            DEALER,
          },
        }
      )
      const { data: dealers } = dealer
      const filterDealer = createFilter(dealers)

      // * Get Pg
      const { data: responsePG } = await xhr.services.get(
        `/v1/bi/getPG`,
        {
          params: {
            DEALER: filterDealer,
            PG,
          },
        }
      )

      const { data: pgs } = responsePG
      // * Get pg_ tool
      const { data: responsePG_Tool } = await xhr.services.get(
        `/v1/bi/getPG_Tool`,
        {
          params: {
            // PG_TOOL: filterPG,
          },
        }
      )
      const { data: pg_Tools } = responsePG_Tool

      // * Get pg 2
      const { data: responsePG_2 } = await xhr.services.get(
        `/v1/bi/getPG2`,
        {
          params: {
            // PG2: filterPG_Tools,
          },
        }
      )
      const { data: pg_2 } = responsePG_2

      // * Get Status
      const { data: responseStatus } = await xhr.services.get(
        `/v1/bi/getStatus`,
        {
          params: {
            // STATUS: filterPG_2,
          },
        }
      )
      const { data: status } = responseStatus

      // * Get Aproved Reference
      const { data: responseAR } = await xhr.services.get(
        `/v1/bi/getAR`,
        {
          params: {
            // STATUS: filterStatus,
          },
        }
      )
      const { data: ar } = responseAR

      // * Get Year BI
      const { data: responseYear } = await xhr.services.get(
        `/v1/bi/getYearBI`,
        {}
      )
      const { data: yearBI } = responseYear
      //* Extraer el mayor año
      const anioDefault = yearBI.map(({ value }) => value)
      const maximo = Math.max(...anioDefault)

      // * Get Regional BI
      const { data: responseRegional } = await xhr.services.get(
        `/v1/bi/getRegional`,
        {
          params: {
            REGIONAL,
          },
        }
      )
      const { data: regionalBI } = responseRegional

      const general = {
        channel: allChannel,
        dealer: dealers,
        pg: pgs,
        pg_tool: pg_Tools,
        pg2: pg_2,
        status,
        ar,
        year: yearBI,
        regional: regionalBI,
        majorCostmr: majorCostmrBI,
        isLoading: true,
        yearSelected: [maximo],
        yearDefault: [{ value: maximo, label: maximo }],
      }
      dispatch(setStateGeneral(general))
    } catch (error) {
      toast.error(error);
    }
  }
}

// * servicio de graficos generales
export const cpsGeneral = (e) => {
  return async (dispatch) => {
    if (e.DEALER && e.DEALER.length > 0 && e.PG && e.PG.length > 0) {
      const { data } = await xhr.services.get(
        `/v1/bi/getDataWithFilters`,
        { params: { ...e } }
      )
      // Asignar Filtros
      dispatch(setFilterWos(e))
      // Asignar Al Store
      dispatch(setChannelpsi(data))
    }
  }
}

// * Servicio de measure
export const cpsMeasure = (e) => {
  return async (dispatch) => {
       const { data } = await xhr.services.get(
      `/v1/bi/getMeasureGeneral`,
      { params: { ...e } }
    )
    // Asignar Al Store
    dispatch(setMeasureGeneral(data))
  }
}

// * Servicio de measure reestructurado
export const cpsNewMeasure = (e) => {
  return async (dispatch) => {
    try {
      if (e.DEALER && e.DEALER.length > 0 && e.PG && e.PG.length > 0) {
        const { data } = await xhr.services.get(
          "/v1/bi/getMeasureGeneral2",
          { params: { ...e } }
        )
        dispatch(setTableNewMeasure(data))
      }
    } catch (error) {
      // Handle the error here
      console.error("Error:", error)
    }
  }
}

// * Servicio de Tabla Channel PSI General
export const cpsTablaGeneral = (e) => {
  return async (dispatch) => {
    const { data } = await xhr.services.get(`/v1/bi/getTableCPSI`, {
      params: { ...e },
    })
    // Asignar Al Store
    dispatch(setTableCPSIGral(data))
  }
}

// * Servicio de Tabla Channel PSI General con el servicio estructurado
export const dataNewCPSI = (e) => {
  return async (dispatch) => {
    try {
      if(e){
        const { data } = await xhr.services.get(`/v1/bi/getTablaCpsiAll`,
          { params: { ...e } }
        )
      // Asignar Al Store
      dispatch(setTableNewCPSI(data))
      dispatch(changeLoading(true))
    }
     } catch (error) {
      // if (error.code === 'ECONNABORTED') {
      //   console.error('Error de tiempo de espera:', error)
      // } else {
      //   console.error( error)
      // }
    }
  }
}

// * Servicio de Tabla Channel PSI con el servicio de acuerdo al DEALER
export const dataNewCPSIFilterDealer = (e) => {
  return async (dispatch, getState) => {
    try {
      const { data } = await xhr.services.get(`/v1/bi/getTablaCPSIWhitOutDealer`, { 
        params: { ['DEALER']: getState().bi_wos.dealer.map(({value}) => value), ...e } 
      })      

      dispatch(setTableCpsiFilterDealer(data))
      dispatch(changingLoadingAllDealers(true))
    } catch(error) {
      dispatch(changingLoadingAllDealers(true))
      dispatch(setTableCpsiFilterDealer({ data :[] }))
    }
  }
}

export const tableCpsiWeek = (anioMes = {}, filtros, index) => {
  return async (dispatch, getState) => {
    try {
      if (!getState().bi_wos.tableCpsiWeeks[index]) {
        const { data } = await xhr.services.get(`/v1/bi/getTablaCpsiWeek`, {
          params: {
            ...filtros,
            ...anioMes
          },
        })
        
        dispatch(setTableCpsiWeeks({
          ...getState().bi_wos.tableCpsiWeeks || {},
          [index] : data
        }))
      }
    } catch (error) {
      //console.log(error)
    }
  }
}

export const tableCpsiDealerWeek = (anioMes = {}, filtros, index) => {
  return async (dispatch, getState) => {
    try {
     if (!getState().bi_wos.tableCpsiDealerWeeks[index]) {
        const { data } = await xhr.services.get(`/v1/bi/getTablaCPSIDealerWeek`, {
          params: {
            ...filtros,
            ...anioMes
          },
        })
        dispatch(setTableCpsiDealerWeeks({
          ...getState().bi_wos.tableCpsiDealerWeeks || {},
          [index] : data
        }))
      }
    } catch (error) {
      //console.log(error)
    }
  }
}

export const resetDealerWeeks = () => {
  return async (dispatch) => {
    dispatch(setTableCpsiDealerWeeks({}))
  }
}
export const resetWeeks = () => {
  return async (dispatch) => {
    dispatch(setTableCpsiWeeks({}))
  }
}
// ! Constantes para la actualizacion de valores de acuerdo a la seleccion de los filtros
// *  Obtener Channels con base a la seleccion en Major Costumer
export const onChannelsMJ = (params = {}) => {
  return async (dispatch) => {
    const { data: filterMAAnt } = await xhr.services.get(
      `/v1/bi/getChannel`,
      { params }
    )
    const { data: majorCostmrBI } = filterMAAnt
    dispatch(setChannel(majorCostmrBI))
  }
}

// *  Obtener Dealers con base a la seleccion en Major Costumer
export const onDealerssMJ = (params = {}) => {
  return async (dispatch) => {
    const { data: filterMAAnt } = await xhr.services.get(
      `/v1/bi/getDealer`,
      { params }
    )
    const { data: majorCostmrBI } = filterMAAnt
    dispatch(setDealer(majorCostmrBI))
  }
}

// *  Obtener Dealers con base a la seleccion de channel
export const onDealerChannels = (params = {}) => {
  return async (dispatch) => {
    const { data: dealer } = await xhr.services.get(
      `/v1/bi/getDealer`,
      { params }
    )
    const { data: dealers } = dealer
    dispatch(setDealer(dealers))
  }
}

export const onPGDealers = (params = {}) => {
  return async (dispatch) => {
    const { data: responsePG } = await xhr.services.get(
      `/v1/bi/getPG`,
      { params }
    )
    const { data: pgs } = responsePG
    dispatch(setPg(pgs))
  }
}

// * Get pg_ tool
export const onPgTOOLPG = (params = {}) => {
  return async (dispatch) => {
    const { data: responsePG_Tool } = await xhr.services.get(
      `/v1/bi/getPG_Tool`,
      { params }
    )
    const { data: pg_Tools } = responsePG_Tool
    dispatch(setPg_Tool(pg_Tools))
  }
}

export const onDealerChannelsDefault = async (params = {}) => {
  try {
    const { data } = await xhr.services.get(`/v1/bi/getDealer`, {
      params,
    })
    return data
  } catch (error) {
    throw new Error(
      error.message || error.stack || "error / line 384 onDealerChannelsDefault"
    )
  }
}

export const onDealerMCDefault = async (params = {}) => {
  try {
    const { data } = await xhr.services.get(`/v1/bi/getDealer`, {
      params,
    })
    return data
  } catch (error) {
    throw new Error(
      error.message || error.stack || "error / line 438 GetFilters_Dealer_On_MC"
    )
  }
}
