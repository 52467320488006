import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import "bootstrap/dist/css/bootstrap.min.css"
import { Tabs, Tab } from "react-bootstrap"
import { GraficosCombinados } from "./generalCharts.jsx"
import { TablaMeasureGral2 } from "./tablas/Layouts/tablaColorsMeasure2.jsx"
import { TablaCpsiAll } from "./tablas/Layouts/CPSI/TableCpsiAll.jsx"
import { TablaCpsiForDealer } from "./tablas/Layouts/CPSI/TableCpsiDealers.jsx"

import { FiltrosAlerts } from "./filtrosAlerts.jsx"
import { TablaAlerts1 } from "./tablas/Layouts/tablaAlerts1.jsx"
import { TableAlerts2 } from "./tablas/Layouts/tablaAlerts2.jsx"
import { GraficosCombinadosAlerts } from "./alertsCharts.jsx"
import { AccordeonFilters } from "./tablas/Layouts/accordeon/accordeon.jsx"

import { Accordeon } from "../accordeon/Accordeon.jsx"
import { TablaPg2 } from "./tablas/Layouts/tablaPg2.jsx"


export const TabsBootstrap = () => {
  const { weeks} = useSelector((state) => state.alerts)
  const { yearSelected } = useSelector((state) => state.bi_wos)
 
  // *Evento para controlar el despliegue Y colapso de las pestañas
  const [pestania, setpestania] = useState(1)
  const eventSelect = (tab) => {
    setpestania(tab)
  }

  useEffect(() => {
    return () => {
      setTimeout(() => {
        // Asignamos DataTable
        $("#tbl_measure").DataTable({// eslint-disable-line
          destroy: true,
          rowReorder: false,
          searching: false,
          ordering: false,
          info: false,
          paging: false,
          fixedHeader: true,
          fixedColumns: {
            left: 1,
            className: "color-fixed",
          },
          borders: true,
        })
      }, 0)
    }
  }, [])

  return (
    <Tabs
      defaultActiveKey="home"
      id="my-tabs"
      activeKey={pestania}
      onSelect={eventSelect}
      style={{ backgroundColor: "#f9f4f6",fontWeight: 'bold',fontFamily: "Arial Narrow"  }}
    >
      <Tab
        eventKey="1"
        title= {`Grafica (${yearSelected})`}       
        unmountOnExit={false}
        style={{ backgroundColor: "#f9f4f6", fontFamily: "Arial Narrow"  }}
      >
        <>
          <AccordeonFilters />
          <div className="col-12 my-2">
            <GraficosCombinados />
          </div>
          <TablaMeasureGral2 />
        </>
      </Tab>
      <Tab
        eventKey="2"
        title="CPSI ALL"
        unmountOnExit={true}
        style={{ backgroundColor: "#f9f4f6",fontFamily: "Arial Narrow"  }}
      >
        <TablaCpsiAll />
        <TablaCpsiForDealer/>
      </Tab>
      <Tab
        eventKey="3"
        title={weeks[0] && weeks[0].value ? `Alerts (${weeks[0].value})` : 'Alerts'}
        unmountOnExit={true}
        style={{ backgroundColor: "#f9f4f6",fontFamily: "Arial Narrow"  }}
      >
        <div className="container-fluid px-0">
          <div className="row row-cols-1 row-cols-xl-2 mb-2">
            <div className="col">
              <Accordeon text='Filtros'>
                <FiltrosAlerts />
              </Accordeon>
            </div>
            <div className="col bg-white">
              <GraficosCombinadosAlerts />
            </div>
          </div>
          <div className="row">
            <div className="col col-lg-12">
              <TablaAlerts1 />
            </div>
            <div className="col col-lg-12">
              <TablaPg2/>
            </div>
            <div className="col col-lg-12">
              <TableAlerts2 />             
            </div>
          </div>
        </div>
        
      </Tab>
    </Tabs>
  )
}
