import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loggedUsers, validateSignInUsers, validateSignSuppliers } from '../../slices';
import { useNavigate } from 'react-router-dom';

export const Login = ({ onLogin, preventDefault }) => {
  const { 
    logged = false, 
    isSuppliers, 
    isUserIdentified, 
    user_id, 
    username:usernameLogin = '',
    first_name
  } = useSelector(state => state.user)
  const [username, setUsername] = useState(usernameLogin) //User: e.moraleszambrano Suppliers: 1004774762@lge.com
  const [password, setPassword] = useState('')
  const [otp, setOtp] = useState('')
  const [path] = useState(localStorage.getItem('lastPath') || '/home')
  const dispatch = useDispatch()
  const navigate = useNavigate()


  const SingIn = async (event) => {
    try {
      await dispatch(
        loggedUsers({
          username
        })
      )
      navigate(`${path}`,
        {
          replace: true
        }
      )

    } catch ({ response }) {
      const { mns = 'Error no Identificado' } = response?.data
      toastr.error(mns) // eslint-disable-line
    }
  }
  //login users LG
  const validateSignIn = async () => {
    try {
      await dispatch(
        validateSignInUsers({
          username,
          password,
          otp
        })
      )
      navigate(`${path}`,
        {
          replace: true
        }
      )
    } catch ({ response }) {
      const { mns = 'Error no Identificado' } = response?.data
      toastr.error(mns) // eslint-disable-line
    }
  }
  // login supplier
  const SingInSuppliers = async () => {
    try {
      await dispatch(
        validateSignSuppliers({
          user_id,
          otp
        })
      )
      navigate(`${path}`,
        {
          replace: true
        }
      )
    } catch ({ response }) {
      // const { message = 'Error no Identificado' } = response?.data
      // toastr.error(message) // eslint-disable-line
    }
  }

  useEffect(() => {
  }, [username])


  useEffect(() => {
  }, [password])


  useEffect(() => {
  }, [otp])

  useEffect(() => {
  }, [logged])



  return (
    <div className="hold-transition login-page">
      <div className="login-box">
        <div className="login-logo">
          <b>G2</b> LG
        </div>
        <div className="card">
          <div className="card-body login-card-body">
            <p className="login-box-msg">{ first_name || `Sign in to startyour session`}</p>
            <div className="input-group mb-3">
              <input
                name="username"
                value={username}
                type="text"
                className="form-control"
                placeholder="User"
                onChange={(e) => setUsername(e.target.value)}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className={isUserIdentified ? "fas fa-user" : "fas fa-envelope"}></span>
                </div>
              </div>
            </div>

            <div className="input-group mb-3">
              {
                isUserIdentified === !isSuppliers && (
                  <div className="input-group mb-3">
                    <input
                      name="password"
                      type="password"
                      value={password}
                      className="form-control"
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-lock"></span>
                      </div>
                    </div>
                  </div>
                )
              }
              {
                (isUserIdentified) &&
                <><input
                  name='otp'
                  type="password"
                  className="form-control"
                  placeholder="OTP"
                  onChange={(e) => setOtp(e.target.value)}
                />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock"></span>
                    </div>
                  </div>
                </>
              }
            </div>

            <div className="row">
              <div className="col-12">
                {
                  isSuppliers
                    ? <button type="button" className="btn btn-block btn-outline-danger btn-lg" onClick={SingInSuppliers}>Login Proveedor</button>
                    : <button type="button" className="btn btn-block btn-outline-danger btn-lg" onClick={isUserIdentified ? validateSignIn : SingIn}> Sign In </button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
