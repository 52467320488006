import React, { useEffect } from "react"
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux"
import {ListMenus} from "./ListMenus";
  
 export const Navigation = (props) => {
  const  { loading } = useSelector( (state) => state.navigation)
  

  const ErrorLoadingMenu = () => {
    return (
      <li className="nav-item">
        <NavLink
          className="nav-link"
        >
          <p className="uppercase">Sin Menus Asignados
          </p>
        </NavLink>
      </li>
    )
  }

  useEffect(() => {
  },[loading]) 

  return (
    <nav className="mt-2">
      <ul
        className="nav nav-pills nav-sidebar flex-column"
        data-widget="treeview"
        role="menu"
        data-accordion="false">
        { loading ? (<ListMenus />) : (<ErrorLoadingMenu/>) }          
      </ul>
    </nav>
  )
}