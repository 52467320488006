import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { tableCpsiWeek } from "../../../../../slices/bi/thunks"
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact"

//TODO: Importar funciones y componentes necesarios
import { getColorResoForc, getColorsCpsi, getColorsWhite } from '../../../../../utils/colorsFunctions'
import { rowsAsignColor } from '../../../../../utils/rowsAsignColor'
import { dataNewCPSI as tablaSlice } from '../../../../../slices/bi/thunks'


//useMemo(() => )
export const TablaCpsiAll = () => {
  const tablaNewCPSI = useSelector((state) => state.bi_wos.table_NewCPSI)
  const tableCpsiWeeks = useSelector((state) => state.bi_wos.tableCpsiWeeks)
  const { filters } = useSelector((state) => state.bi_wos)
  const [rows, setRows] = useState(Array(17))
  const [rowsData, setRowsData] = useState(Array(17))
  const [expandedData, setExpandedData] = useState({})
  const dispatch = useDispatch()
  //* variables de Cpsi All
  const mes = tablaNewCPSI.MES
  const weeks = tablaNewCPSI.Measure
  const ResultForcast = tablaNewCPSI.WEEK_TIPO
  const years = tablaNewCPSI.YEAR_1
  const { titulosFirstTable,dealer, pg,channel } = useSelector((state) => state.bi_wos)

  const handleClick = (index, header) => {
    const params = {
      YEAR_1: years[index],
      MES: header
    }

    dispatch(tableCpsiWeek(params, filters, index))
    setExpandedData({
      ...expandedData,
      [index]: !expandedData[index],
    })
  }
  
  useEffect(() => {
    let dealers = {
      'DEALER': dealer.map(({value}) => value)
    }
    let pgcpsi = {
      'PG': pg.map(({value}) => value)
    }
    let CHANNEL = {
      'CHANNEL': channel.map(({value}) => value)
    }
    dispatch(tablaSlice({ ...CHANNEL,...dealers, ...pgcpsi, ...filters }))
     // eslint-disable-next-line 
  }, [dealer, pg, channel,filters])

  // * Valores de las columnas store
  const titulosFirst = titulosFirstTable
  
  // carga los datos de la tabla por meses
  useEffect(() => {
    //scope
    setRows([
      tablaNewCPSI[`P |PM|`],
      tablaNewCPSI[`P |KAM|`],
      tablaNewCPSI[`P |Y-1|`],
      tablaNewCPSI[`S |R+F|`],
      tablaNewCPSI[`S |Y-1|`],
      tablaNewCPSI[`S |R+F| USD`],
      tablaNewCPSI[`S |Y-1| USD`],
      tablaNewCPSI[`USD ∆ VS Y-1`],
      tablaNewCPSI[`S |R+F| COP`],
      tablaNewCPSI[`S |Y-1| COP`],
      tablaNewCPSI[`COP ∆ VS Y-1`],
      tablaNewCPSI[`S FCST W-1`],
      tablaNewCPSI[`AR% FCST W-1`],
      tablaNewCPSI[`SELLABLE INV`],
      tablaNewCPSI[`DISPLAY INV`],
      tablaNewCPSI[`WOS-4 |QTY|`],
      tablaNewCPSI[`WOS+8 |QTY|`],
    ])
  }, [tablaNewCPSI])
  
  // carga los datos de la tabla interna que es por semanas
  useEffect(() => {
    setRowsData(tableCpsiWeeks)
  }, [tableCpsiWeeks])


  return (
    <div className="row table-wrapper bg-white">
      <MDBTable
        className="my-table alineacion tamanioLetra"
        bordered
        small
        responsive={false}
        hover
        id='tbl_CpsiAll'
      >
        <MDBTableHead style={{ position: 'sticky', top: '0', zIndex: '1' }}>
          <tr style={{ fontFamily: "Arial Narrow" }}>
            <th style={{ position: "sticky", left: 0 }}></th>
            <th style={{ position: "sticky", left: 80 }} key="Measure" className="titulos"></th>
            {years.map((header, i) => (
              <React.Fragment key={i}>
                {expandedData[i] && tableCpsiWeeks[i] && tableCpsiWeeks[i].YEAR_1 && tableCpsiWeeks[i].YEAR_1.map((header, j) => (
                  <th key={j} style={
                    header === 2023 ? { color: '#008000', backgroundColor: '#FFFFFF' } : getColorsWhite(header)}>
                    {header}
                  </th>
                ))}
                <th style={header === 2023 ? { color: '#008000', backgroundColor: '#FFFFFF' } : getColorsWhite(header)}>{header}</th>
              </React.Fragment>
            ))}
          </tr>
          <tr>
          <th style={{ position: "sticky", left: 0 }}></th>
            <th style={{ position: "sticky", left: 80 }} key="Measure" className="titulos"></th>
            {ResultForcast.map((header, i) => (
              <React.Fragment key={i}>
                {expandedData[i] && tableCpsiWeeks[i] && tableCpsiWeeks[i].WEEK_TIPO && tableCpsiWeeks[i].WEEK_TIPO.map((r, i) => (
                  <th 
                  style={getColorResoForc(r)}
                  key={i}>{r}</th>
                  
                ))}
                <th style={getColorResoForc(header)} >
                  {header}
                </th>
              </React.Fragment>
            ))}
          </tr>
          <tr>
            <th style={{ position: "sticky", left: 0 }}></th>
            <th style={{ position: "sticky", left: 80 }} key="Measure" className="titulos"></th>
            {mes.map((header, i) => (
              <React.Fragment key={i}>
                {
                  expandedData[i] && tableCpsiWeeks[i] && tableCpsiWeeks[i].MES && tableCpsiWeeks[i].MES.map((r, j) => (
                    <th key={j} style={getColorsWhite(r)}>{r}</th>
                  ))
                }
                <th style={getColorsCpsi(header)} onClick={() => handleClick(i, header)}>
                <span style={{color: 'green'}}>&#43;</span> {header}
                </th>
              </React.Fragment>
            ))}
          </tr>

          <tr>
            <th style={{ position: "sticky", left: 0 }}></th>
            <th style={{ textAlign: "left", fontFamily: "Arial Narrow", fontWeight: "bold", position: "sticky", left: 80 }} key="Measure" className="titulos">MEASURE</th>
            {weeks.map((header, i) => (
              <React.Fragment key={i}>
                {expandedData[i] && tableCpsiWeeks[i] && tableCpsiWeeks[i].Measure.map((header, j) => (
                  <th key={j} style={getColorsWhite(header)}>
                    {header}
                  </th>
                )
                )}
                <th style={getColorsCpsi(header)}>
                  {header}
                </th>

              </React.Fragment>
            ))}

          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {rows.map((titulo, i) => (<tr key={i} >
            <td style={{ textAlign: "left", fontFamily: "Arial Narrow", fontWeight: "bold", position: "sticky", left: 0, color: '#f4f6f9' }} className="titulos">
              {titulosFirst[i]}
            </td>
            <td style={{ textAlign: "left", fontFamily: "Arial Narrow", fontWeight: "bold", position: "sticky", left: 80 }} className="titulos">
              {titulosFirst[i]}
            </td>

            {titulo.map((value, j) => {
              const title = titulosFirst[i]
              const val = value
              const processEnvColorUpArrow = process.env.REACT_APP_COLOR_UP_ARROW

              let style = rowsAsignColor(title, val)
              style = (val === null && (title === 'USD ∆ VS Y-1' || title === 'COP ∆ VS Y-1')) ?
                {
                  ...style,
                  backgroundColor: title === 'USD ∆ VS Y-1' ? '#e2efda' : '#fce4d6',
                  color: processEnvColorUpArrow,
                  backgroundImage: `url('/dist/img/uparrow.png')`,
                  backgroundPosition: '10% 40%',
                  backgroundSize: '0.3vw',
                  backgroundRepeat: 'no-repeat'
                } :
                (val === null && title === 'AR% FCST W-1') ?
                  {
                    ...style,
                    color: '#C00000',
                    backgroundColor: '#FFF2CC'
                  } :
                  style

              return (
                <React.Fragment key={j}>
                  {/* datos de la tabla interna que es por semanas, esta de primero para que se expanda antes de los totales */}
                  {expandedData[j] && rowsData[j] &&
                    Object.values(rowsData[j][title]).map((header, k) => {
                      const title = titulosFirst[i]
                      return <td key={k} style={
                        (header === null && (title === 'USD ∆ VS Y-1' || title === 'COP ∆ VS Y-1'))
                          ? {
                            ...rowsAsignColor(title, header),
                            backgroundColor: title === 'USD ∆ VS Y-1' ? '#e2efda' : '#fce4d6',
                            color: process.env.REACT_APP_COLOR_UP_ARROW,
                            backgroundImage: `url('/dist/img/uparrow.png')`,
                            backgroundPosition: '10% 40%',
                            backgroundSize: '0.3vw',
                            backgroundRepeat: 'no-repeat'
                          }
                          : (header === null && title === 'AR% FCST W-1')
                            ? { ...rowsAsignColor(title, header), color: '#C00000', backgroundColor: '#FFFFFF' }
                            : (title === 'P' || title === 'P |PM|' || title === 'P |KAM|' || title === 'P |Y-1|' ||
                              title === 'S FCST W-1' || title === 'AR% FCST W-1' || title === 'SELLABLE INV' ||
                              title === 'DISPLAY INV')
                              ? { ...rowsAsignColor(title, header), backgroundColor: '#FFFFFF' }
                              : rowsAsignColor(title, header)
                      }>
                        {(title === 'USD ∆ VS Y-1' || title === 'COP ∆ VS Y-1' || title === 'AR% FCST W-1')
                          ? (header === null) ? `${parseInt(0)}%`
                            : `${parseFloat(header).toFixed(0)}`.replace(/\D/, '') + '%'
                          : (header === 0 || header === '0' || header === null || header === '' || header === undefined)
                            ? '-'
                            : header}
                      </td>
                    })
                  }

                  {/* datos de la tabla padre */}
                  <td style={style}>
                    {(title === 'USD ∆ VS Y-1' || title === 'COP ∆ VS Y-1' || title === 'AR% FCST W-1') ?
                      (val === null) ? `${parseInt(0)}%` : `${parseFloat(val).toFixed(0)}`.replace(/\D/, '') + '%' :
                      (val === 0 || val === '0' || val === null || val === '' || val === undefined) ?
                        '-'  : val}
                  </td>
                </React.Fragment>
              )})}
           </tr>
           ))}
        </MDBTableBody>
      </MDBTable>
    </div>
  )
}
