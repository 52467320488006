import React from 'react';
import { NavLink } from "react-router-dom"
import { useDispatch, useSelector} from "react-redux"
import { asignPathStore } from '../../slices/navigation/navigationThunks';
import { useEffect } from 'react';

export const NavigationMenu = ({items = []}) => {
    const dispatch = useDispatch()
    const  { loading } = useSelector( (state) => state.navigation)

    const setPath = async (link = '') => {
      try {
        //dispatch(asignPathStore(link) )
      } catch (error) {
        //console.error(error)
      }
    }
    const Nav = ( {item} ) => {
      const { path,name,icon} = item
      return (<NavLink
        to={path}
        className="nav-link"
        onClick={() => setPath(path)}
      >
          <i className={icon} />
          <p className="uppercase">{name}</p>
      </NavLink>)
    }
    
    useEffect(() => {      
    }, [])

    return loading && (
      <>
        {
          items.map((item, index) => (
            <li className="nav-item" key={index}>
              <Nav item={item}/>
            </li>
            )
          )
        } 
      </>
    )
}