import { createSlice } from "@reduxjs/toolkit";

export const navigationSlice = createSlice({
  name: "navigation",
  initialState: {
    lastPath: '',
    menus: [],
    loading: false,
  },
  reducers: {
    changePath: ( state, {payload}) => {
      state.lastPath = payload
    },
    setMenu: ( state, {payload}) => {
      state.menus = payload
    },
    setLoading: ( state, {payload}) => {
      state.loading = payload
    },
    resetMenu: (state) => {
      state.menus = []
    },    
    changeChildren: (state, {payload}) => {
      state.menus[payload].loading_children = !state.menus[payload].loading_children || false
      
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  changePath,
  setMenu,
  resetMenu,
  setLoading,
  changeChildren
} = navigationSlice.actions
