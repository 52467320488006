import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ontablaAlertsModel } from "../../../slices/bi/alertsThunks";

import { rowsAsignColor } from '../../../utils/rowsAsignColor';
import { TableModel } from './TableModel';

//anidacion 
export const TableDescription = ({ index }) => {
  const { tableAlersInd, tablaAlertsModel, filtrosAlerts } = useSelector(
    (state) => state.alerts
  );
  const [expandedRowsModel, setExpandedRowsModel] = useState({});
  const dispatch = useDispatch();
  // Función para alternar la expansión de una fila 
  const toggleRowExpansionModel = (index, rowsdata) => {
    const params = {
      ...filtrosAlerts,
      PG: rowsdata.PG,
      DEALER: rowsdata.COSTUMER,
      PG2: rowsdata.PG2,
    }

    //asignacion de valores
    dispatch(
      ontablaAlertsModel(index, params)
    );

    if (tablaAlertsModel !== undefined && tablaAlertsModel !== null && tablaAlertsModel !== '') {
      // console.log('data',tablaAlertsModel)
      setExpandedRowsModel({
        ...expandedRowsModel,
        [index]: !expandedRowsModel[index],
      });
    }
  };

  let cantidad = tableAlersInd[index]?.length || 0;
  return (
    <div  style={{fontFamily: "Arial Narrow" }} >
      <div className="col-12">
        {!!cantidad && (

          <table
            className="table table-bordered  my-table alineacion tamanioLetra"
          >
            <thead>
              <tr>
                {Object.keys(tableAlersInd[index][0]).map((column, indice) => (
                  <th key={`tbl_ind_th_${indice}`} className="titulos" style={{ whiteSpace: 'pre', wordWrap: 'break-word', lineHeight: '1.2', verticalAlign: 'middle',fontFamily: "Arial Narrow" }}>
                    {column}
                  </th>
                ))}
              </tr>
            </thead>
            {<tbody>
              {tableAlersInd[index].map((rowData, indice) => (
                <React.Fragment key={indice}>
                  <tr
                    onClick={() => {
                      if (indice !== tableAlersInd[index].length - 1) {
                        toggleRowExpansionModel(indice, rowData);
                      }
                    }}
                    style={{ fontFamily: "Arial Narrow", cursor: "pointer", fontWeight: indice === tableAlersInd[index].length - 1 ? 'bold' : 'normal' }}
                  >
                    {Object.keys(tableAlersInd[index][indice]).map(
                      (column, colIndex) => (
                        <td style={rowsAsignColor(column, rowData[column])} key={colIndex + Object.values(rowData).slice(1, 7).map(r => r)}>
                          {rowData[column] === "0"|| rowData[column] === null ? '-' : 
                            (rowData[column] === 'WOS -4\n|QTY|' || rowData[column] === 'WOS +8\n|QTY|')
                             ? parseFloat(rowData[column]).toFixed(1)
                             :(rowData[column] < 0 ? rowData[column].toString().replace('-', '') : rowData[column])
                          }
                        </td>
                      )
                    )}
                  </tr>
                  {expandedRowsModel[indice] && (
                    <tr>
                      <td colSpan={Object.keys(tableAlersInd[index][indice]).length}>
                        <TableModel index={indice} data={rowData} />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
            }
          </table>
        )}
      </div>
    </div>
  );
};