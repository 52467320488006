import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
//import { getISOWeek, subWeeks } from "date-fns";

// Importar acciones y funciones asíncronas de Redux
import {
  alerts_Filters,
  alerts_FiltersBI,
  tablaAlerts1,
  tablaAlerts2,
  graficaAlerts,
  tablaPg2,
} from "../../slices/bi/alertsThunks";

// Definir el componente funcional principal
export const FiltrosAlerts = () => {
  // Extraer datos necesarios del estado Redux utilizando el gancho useSelector
  const { channelAlerts, dealerAlerts, invDistr, filtroAlertsBI, pgAlerts} = useSelector((state) => state.alerts);
  const { pg: PG, pg2: PG2, status: Status, permissionKams } = useSelector((state) => state.bi_wos);


  // Extraer DEALER de permissionKams e inicializar variables de estado
  const [{ DEALER }] = useState(permissionKams)
  const [filters, setFilters] = useState({  });
  const dispatch = useDispatch();

  // Función para validar y actualizar el filtro específico
const updateFilters = (name, values = []) => {
  const data = values.map(({ value }) => value);

  // Objeto de valores iniciales según el filtro
  const initialValuesMap = {
    DEALER: dealerAlerts.map(({ value }) => value),
    PG: pgAlerts.map(({ value }) => value),
    'INVENTORY DISTRIBUTION': invDistr.map(({ value }) => value),
    STATUS: Status.map(({ value }) => value),
    PG2: PG2.map(({ value }) => value),
  }
  
  // Restablecer los datos a los valores iniciales según el filtro.

  setFilters((state) => ({
    ...state,
    [name]: (data.length === 0) ? initialValuesMap[name] : data
  }))

}

  // Función para actualizar los filtros según los valores de canal seleccionados
  const updateFiltersChannel = (name, values = []) => {
    // const dealerOnchannels = values.map(({ value }) => value)
    // let filterValueChannel = {
    //   [name]: dealerOnchannels,
    // }
  }

   // Función para manejar las alertas seleccionadas y actualizar los filtros en consecuencia
  const getDataFilterBI = (e) => {
    let alerts = {}
    // limpiar
    filtroAlertsBI.forEach( ({ value }) => {
      alerts[value] = undefined
    })
    // reasignar
    e.forEach(({ value }) => {
      alerts = {
        ...alerts,
        [value]: "Op.ne"
      }
    })
       
    setFilters((state) => ({
      ...state,
      ...alerts
    }))
  }
  
  //Función para despachar acciones Redux de datos de tabla y gráfica al hacer clic en el botón
  const enviarSeleccion = () => {
    dispatch(tablaAlerts1(filters))
    dispatch(tablaPg2(filters))
    dispatch(graficaAlerts(filters))
    dispatch(tablaAlerts2(filters), (()=> {
      $('#collapseOne').collapse('hide') // eslint-disable-line
    })()) 
   
     }

      // useEffect para despachar acciones Redux de los Dealers y los PG llenarlas con el store cuando esten vacías
  useEffect(() => {
    let dealers = { DEALER: dealerAlerts.map(({value}) => value) }
    let pg = { PG: pgAlerts.map(({value}) => value) }
    let inv = { 'INVENTORY DISTRIBUTION': invDistr.map(({value}) => value) }
    let status = { STATUS: Status.map(({value}) => value) }
    let pg2 = { PG2: PG2.map(({value}) => value)  }

     setFilters((state) => ({
      ...state,
      ...dealers,
      ...inv,
      ...pg,
      ...status,  
      ...pg2,    
    }))
     // eslint-disable-next-line 
 }, [dealerAlerts,pgAlerts,invDistr,Status])
   // useEffect para despachar acciones Redux de las alertas llenarlas con todas undefined cuando esten vacías
  useEffect(() => {
    let alerts = {}
    filtroAlertsBI.forEach( ({ value }) => {
      alerts[value] = undefined
    })
    setFilters((state) => ({
      ...state,
      ...alerts
    }))
  },[filtroAlertsBI])
  // useEffect para despachar acciones Redux de filtros y datos de tabla al montar el componente
  useEffect(() => {
    // * filtros
    dispatch(alerts_Filters())
    dispatch(alerts_FiltersBI())
    // * Datos de la tabla
    dispatch(tablaAlerts1(filters))
    dispatch(tablaPg2(filters))
    dispatch(graficaAlerts(filters))
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

 // Renderizar el componente según el estado de carga
  return (
    <div className="bg-white py-3" style={{ fontFamily: "Arial Narrow" }}>
      <div className="row d-flex justify-content-center py-1 px-3">
        <div className="col-12 col-md-6 col-lg-4">
          <label>Channel</label>
          <Select
            id="channel"
            label="Channel"
            placeholder="Filtro de Channel"
            options={channelAlerts}
            isMulti
            maxSelections="5"
            //defaultValue={channelAlerts}
            onChange={(e) => {
              updateFiltersChannel("CHANNEL", e);
            }}
            name="CHANNEL"
          />
        </div>
        <div className="col-12 col-md-6 col-lg-4">
          <label>Dealer</label>
          <Select
            id="dealer"
            label="Dealer"
            placeholder="Filtro de Dealer"
            options={dealerAlerts}
            name="DEALER"
            onChange={(e = []) => {
              let dealerDefault = !e.length
                ? DEALER.map((value) => ({ label: value, value }))
                : e;
              updateFilters("DEALER", dealerDefault);
            }}
            isMulti
          />
        </div>
        <div className="col-12 col-md-6 col-lg-4">
          <label>PG</label>
          <Select
            id="pg"
            options={PG}
            label="Pg"
            placeholder="PG"
            name="PG"
            isMulti
            onChange={(e) => {
              updateFilters("PG", e);
              
            }}
          />
        </div>
        <div className="col-12 col-md-6 col-lg-4">
          <label> Alertas</label>
          <Select
            id="seleccionFiltro"
            options={filtroAlertsBI}
            label="Alertas"
            placeholder="Alertas"
            name="alertas"
            isMulti
            onChange={(e) => {
              getDataFilterBI(e)
            }}
            isClearable={true}
          />
        </div>
        <div className="col-12 col-md-6 col-lg-4">
          <label>Inventory Distribution</label>
          <Select
            id="inventory_distribution"
            options={invDistr}
            label="Inventory Distribution"
            placeholder="Filtro de Inventory Dist..."
            name="INVENTORY DISTRIBUTION"
            isMulti
            onChange={(e) => {
              updateFilters("INVENTORY DISTRIBUTION", e);
            }}
          />
        </div>
        <div className="col-12 col-md-6 col-lg-4">
          <label>PG2</label>
          <Select
            id="pg2"
            options={PG2}
            label="Pg2"
            isMulti
            placeholder="Filtro de PG2"
            name="PG2"
            onChange={(e) => {
              updateFilters("PG2", e);
            }}
          />
        </div>
        <div className="col-12 col-md-6 col-lg-4">
          <label>Status</label>
          <Select
            id="status"
            options={Status}
            label="Status"
            isMulti
            placeholder="Filtro de Status"
            name="STATUS"
            onChange={(e) => {
              updateFilters("STATUS", e);
            }}
          />
        </div>
        <div className="col-12 col-md-6 col-lg-8 pt-2">
          <button
            type="button"
            id="enviar_dato"
            name="enviar_dato"
            className="btn text-white raise mt-4"
            style={{
              cursor: "pointer",
              //marginTop: "30px",
              backgroundColor: "#dc3545",
            }}
            onClick={enviarSeleccion}
          >
            Enviar Datos
          </button>
        </div>
      </div>
    </div>
  );
};
