import { configureStore } from '@reduxjs/toolkit';

import   {userSlice, wosSlice, alertsSlice} from '../slices';
import { navigationSlice } from '../slices/navigation/navigationSlice';
import { pagosSlice } from '../slices/pagos/pagosSlice';


export const store = configureStore({
  reducer: {
    bi_wos: wosSlice.reducer,
    user: userSlice.reducer,
    alerts : alertsSlice.reducer,
    navigation: navigationSlice.reducer,
    pagos: pagosSlice.reducer
  },
})