//import classNames from 'classnames';

const percentRegex = /^\d+(\.\d+)?%$/;

export const getColor = (value) => {
  let styles = {
    backgroundColor: "#FFFFFF", 
    color: "#C00000", 
    fontFamily: "Arial Narrow"
  }
  styles = (value < 95 || value > 110) ? {
      ...styles,
    }:{
      ...styles,
      color: "#008000",
    }
   return styles
}
export const getColorCpsi = (value) => {
  let styles = {
    backgroundColor: "#FFF2CC", 
    color: "#C00000", 
    fontFamily: "Arial Narrow"
  }
  styles = (value < 95 || value > 110) ? {
      ...styles,
    }:{
      ...styles,
      color: "#008000",
    }
   return styles
}
export const getColorWos = (value) => {
  return value <= 5
    ? { backgroundColor: "pink", color: "#C00000", fontWeight: "bold" }
    : value >= 8
    ? { backgroundColor: "#ffc000", color: "#C00000" }
    : { backgroundColor: "#CBC8C2" };
}
export const getColor2WosMeasure = (value) => {
  let styles = {
    backgroundColor: "white" 
  }
  styles =( value <= 5 ) ? {backgroundColor: "pink", color: "#C00000"} :
  styles =( value >= 8 ) ? {backgroundColor: "#ffc000", color: "#C00000"} :
 { ...styles}

 return styles
}
export const getColorWhite = () => {
  let styles = {
    backgroundColor: "#FFFFFF",
    fontFamily: "Arial Narrow"
  }
  return styles
}
export const colorsCPSIimg = (value) =>{
  let styles = {
    backgroundColor: "#e2efda",
    backgroundSize: '0.3vw',
    backgroundPosition:'10% 40%',
    backgroundRepeat: 'no-repeat',
    fontFamily: "Arial Narrow"
  }
  styles = (value <= 0) ? {
    ...styles,
    color: process.env.REACT_APP_COLOR_DOWN_ARROW,
    backgroundImage: `url('/dist/img/downarrow.png')`
  } : 
  styles = (value > 0) ? {
    ...styles,
    color: process.env.REACT_APP_COLOR_UP_ARROW,
    backgroundImage: `url('/dist/img/uparrow.png')`
  } : {...styles}

  return styles
     
}
export const FCSTW1CPSIimg = (value) =>{

  let styles = {
    backgroundColor: "#fce4d6",
    backgroundSize: '0.3vw',
    backgroundPosition: '10% 40%',
    backgroundRepeat: 'no-repeat',
    fontFamily: "Arial Narrow"
  }
  styles = (value <= 0) ? {
    ...styles,
    color: process.env.REACT_APP_COLOR_DOWN_ARROW,
    backgroundImage: `url('/dist/img/downarrow.png')`
  } : 
  styles = (value > 0) ? {
    ...styles,
    color: process.env.REACT_APP_COLOR_UP_ARROW,
    backgroundImage: `url('/dist/img/uparrow.png')`
  } : {...styles}

  return styles
}
export const getColor4 = () => {
  let styles = {
    backgroundColor: "#FFFFFF" , 
    fontFamily: "Arial Narrow"
  }
  return styles
}
export const getColor5 = (value) => {
   let styles = {
    backgroundColor: "green",
    fontFamily: "Arial Narrow",
    color: "green" 
   }
   styles = (value <= 10) ? {
    ...styles,
    backgroundColor: "brown"
   } : styles = (value >= 18) ? {
    ...styles,
    color: "#C00000" 
   } : styles = (value >= 75) ? {
    ...styles,
    backgroundColor: "yellow" 
   } : styles = (value <= 100) ? {
    ...styles,
    color: "yellow" 
   } : { ...styles}

  return styles
};
export const getColorsWhite = () => {
 let styles = {
    backgroundColor: "#FFFFFF",
    fontFamily: "Arial Narrow"
  }
  return styles
}
export const getColorsCpsi = () => {
  let styles = {
     backgroundColor: "#FFF2CC",
     fontFamily: "Arial Narrow"
   }
   return styles
 }
export const getColorsS = () => {
  let styles = {
    backgroundColor: "#d9d9d9",
    fontFamily: "Arial Narrow" 
  }
  return styles
}
export const getColorsUSD = () => {
  let styles = {
    backgroundColor: "#e2efda",
    fontFamily: "Arial Narrow" 
  }
    return styles
}
export const getColorsCOP = () => {
  let styles = { 
      backgroundColor: "#fce4d6",
      fontFamily: "Arial Narrow" 
    }
    return styles
}
export const getColorGPT = (value) => {
  let styles ={
    backgroundColor: "#fff2cc",
    fontFamily: "Arial Narrow" 
  }
   styles = (Math.abs(value) >= 100 && Math.abs(value) <= 130) ? {
    ...styles, color: "green"
  } : styles = (Math.abs(value) >= 80 && Math.abs(value) <= 99) ? {
    color: "#C00000"
  } : styles = (value >= -8 && value <= -4) ?  {
    ...styles, color: "#C00000"
  } : styles = (value >= -16.6 && value < -8) ? {
    ...styles, color: "#C00000" 
  } : {
    ...styles
  }
  if (percentRegex.test(value)) {
    const percentage = parseFloat(value)
      let styles = {
        backgroundColor: "#fff2cc",
        fontFamily: "Arial Narrow"
      }
      // eslint-disable-next-line 
      styles = (percentage > 100 || Math.abs(value) <= 150) ? {
          ...styles,
          color: "#008000"
      } : {
          color: "#C00000"
      }
  }
  return styles;
};
export const getColorPercentCOP = (value) => {
  if (Math.abs(value) >= 100 && Math.abs(value) <= 130) {
    return { backgroundColor: "#fce4d6", color: "green" };
  }
  if (Math.abs(value) >= 80 && Math.abs(value) <= 99) {
    return { color: "#C00000" };
  }
  if (value >= -8 && value <= -4) {
    return { backgroundColor: "#fce4d6", color: "#C00000" };
  }
  if (value >= -16.6 && value < -8) {
    return { backgroundColor: "#fce4d6", color: "#C00000" };
  }
  if (percentRegex.test(value)) {
    const percentage = parseFloat(value);
    if (percentage > 100 || Math.abs(value) <= 150) {
      return {
        backgroundColor: "#fce4d6",
        color: "#008000",
        fontWeight: "bold",
      };
    } else {
      return { backgroundColor: "#fce4d6", color: "#C00000", fontWeight: "bold" };
    }
  }
  return { backgroundColor: "#fce4d6" };
};
export const getColorPercentUSD = (value) => {
  let styles ={
    backgroundColor: "#e2efda",
    fontFamily: "Arial Narrow"
  }
  if (percentRegex.test(value)) {
    const percentage = parseFloat(value);
    styles = (percentage >= 100) ?{
      ...styles,
      color: "#008000",
    } : {
     ...styles,
      color: "#C00000",  
    }  
  }
  return styles;
}
export const getColorPercentWhite = (value) => {
 let styles = {
    backgroundColor: "#FFFFFF", 
    color: "#C00000", 
    fontFamily: "Arial Narrow"
  }
  if (percentRegex.test(value)) {
    const percentage = parseFloat(value)
    styles = (percentage >= 95 && percentage <= 110 && percentage > 0) ? {
    // Si está entre 95 y 110, píntalo de verde
      ...styles,
      backgroundColor: "#FFFFFF", 
      color: "#008000"
    } : {
    // Si es negativo, píntalo de rojo
      ...styles,
      backgroundColor: "#FFFFFF",
      color: "#C00000"
    }
  }
  // En otros casos, no cambies el color
  return styles
}
export const getColorResoForc = (value) => {
  let estilos = {
    fontFamily: "Arial Narrow",
    backgroundColor: "#FFFFFF",
    color: "pink"
  }
  if (typeof value === "string") {
    estilos = (value === "Result") ?
       {
        ...estilos,
        backgroundColor: "#E2EFDA", 
        color: "#008000"
      } :
      {
        ...estilos,
        backgroundColor: "#ffc7ce", 
        color: "#C00000"
      }
  }
  return estilos
}
export const getColorWos_4QTY = (value) => {
  const valorFloat = parseFloat(value)
  let styles = {
    backgroundColor: "#ffc000", 
    fontFamily: "Arial Narrow"
  }
  styles = (valorFloat <= 8) ? {...styles} : {...styles, backgroundColor: "#FFFFFF", }
  return styles
}
export const getColorDealerAlerts = () => {
  let styles = {
    backgroundColor: "#FFFFFF", 
    fontFamily: "Arial Narrow"
  }
  return styles
}
export const getColorsWhites = () => {
  let styles = {
    backgroundColor: "#CBC8C2", 
    fontFamily: "Arial Narrow"
  }
  return styles
}
export const getSOW1 = () => {
  let styles = {
    backgroundColor: "#FFF2CC", 
    fontFamily: "Arial Narrow"
  }
  return styles
}
export const getR_F= () => {
  let styles = {
    backgroundColor: "#E2EFDA", 
    color: "#008000",
    fontFamily: "Arial Narrow"
  }
  return styles
 }
export const getR_F1= (value) => {
  let styles = {
    backgroundColor: "#E2EFDA", 
    color: "#008000",
    fontFamily: "Arial Narrow"
  }
    if (percentRegex.test(value)) {
      const percentage = parseFloat(value)
      styles = (percentage >= 100) ? {
        ...styles
      }: {
        ...styles,
        color: "#C00000" 
      } 
    }
    return styles
}
export const getBackground = (value) => {
  let styles = {
    background: "#E2EFDA", 
    fontFamily: "Arial Narrow"
  }
  const intValue = parseInt(value)
  const percentage = intValue + '%'
  styles = (!isNaN(intValue)) ? {
    ...styles,
    background: `linear-gradient(to right, #4CAF50 0%, #E2EFDA ${percentage}, white ${percentage}, white 100%)`,
  } : {
    ...styles
  }
  return styles
};
export const getCellWithArrow = (value) => {  
  let floatValue = parseFloat(value);
  const isValueValid = !isNaN(floatValue);
  if (!isValueValid) {
    return { backgroundColor: 'white', fontFamily: "Arial Narrow" };
  }

  if (floatValue >= 50) {   
    return {
      backgroundColor: 'white', 
      color: process.env.REACT_APP_COLOR_UP_ARROW,
      backgroundImage: `url('/dist/img/uparrow.png')`,
      backgroundSize: '0.4vw',
      backgroundPosition: '80% 50%',
      backgroundRepeat: 'no-repeat',
      fontFamily: "Arial Narrow"
    };
  }

  if (floatValue < 50) {
    return {
      backgroundColor: 'white', 
      color: process.env.REACT_APP_COLOR_DOWN_ARROW,
      backgroundImage: `url('/dist/img/downarrow.png')`,
      backgroundSize: '0.4vw',
      backgroundPosition: '80% 50%',
      backgroundRepeat: 'no-repeat',
      fontFamily: "Arial Narrow"
    };
  }
};
export const getDeltas2= (value) => {  
  let floatValue = parseFloat(value)
  const isValueValid = !isNaN(floatValue)

  if (!isValueValid) {
    return { backgroundColor: 'white' }
  }

  if (floatValue >= 0) {
    return {
      backgroundColor: '#FFF2CC',
      color: process.env.REACT_APP_COLOR_DOWN_ARROW,
      backgroundImage: `url('/dist/img/delta.png')`,
      backgroundSize: '0.4vw',
      backgroundPosition: '95% 50%',
      backgroundRepeat: 'no-repeat',
    }
  }
  if (floatValue < 0) {
    return {
      backgroundColor: '#FFF2CC',
      color: process.env.REACT_APP_COLOR_UP_ARROW,
      backgroundImage: `url('/dist/img/flecha-delta.png')`,
      backgroundSize: '0.5vw',
      backgroundPosition: '98% 50%',
      backgroundRepeat: 'no-repeat',
    }
  }
}
export const getDeltas = (value) => {
  let floatValue = parseFloat(value)
  const isValueValid = !isNaN(floatValue)

  if (!isValueValid) {
    return { backgroundColor: 'white' }
  }

  if (floatValue >= 0) {
    return {
      backgroundColor: '#CBC8C2',
      color: process.env.REACT_APP_COLOR_DOWN_ARROW,
      backgroundImage: `url('/dist/img/delta.png')`,
      backgroundSize: '0.4vw',
      backgroundPosition: '95% 50%',
      backgroundRepeat: 'no-repeat',
    }
  }
  if (floatValue < 0) {
    return {
      backgroundColor: '#CBC8C2',
      color: process.env.REACT_APP_COLOR_UP_ARROW,
      backgroundImage: `url('/dist/img/flecha-delta.png')`,
      backgroundSize: '0.5vw',
      backgroundPosition: '98% 50%',
      backgroundRepeat: 'no-repeat',
    }
  }
}