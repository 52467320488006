import React from 'react'



export const TituloGral = ({
    titulo
}) => {

  //const [autenticacion, setAutenticacion] = useState(false)

  /*const eventLogin = () => {
    setAutenticacion(true)
  }*/
  return (
    <>
    </>
  //   <div className="content-header">
  //   <div className="container-fluid">
  //     <div className="row mb-2">
  //       <div className="col-sm-6">
  //         <h1 className="m-0">{titulo}</h1>
  //       </div>
  //       <div className="col-sm-6">
  //         <ol className="breadcrumb float-sm-right">
  //           <li className="breadcrumb-item"><a href="index.html">Home</a></li> 
  //           <li className="breadcrumb-item active">Dashboard vx</li>
  //         </ol>
  //       </div>
  //     </div> 
  //   </div>
  // </div>
  )
}