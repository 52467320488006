import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import Modal from '../../modal/Modal';
import { CardInvoice } from '../../Cards/CardInvoice';
import { facturasProveedor, facturasporfechaProveedor, pagosFactura, detallesFactura } from '../../../slices/pagos/pagosThunks';
import { Loading } from '../../loadings/Loading';
//import Pagination from '../../pagination/Pagination';
import { resetdetallesDB, resetpagosDB } from '../../../slices/pagos/pagosSlice';
import { PaymentTableFacturas } from '../../PaymentTable/PaymentTableFacturas';
import { formatCurrency } from '../../../utils/formatCurrency';

const Facturas = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [invoice, setInvoice] = useState('')
  // const [currentPage, setCurrentPage] = useState(1);
  // const [recordsPerPage] = useState(5);

  const dispatch = useDispatch();
  const { facturasDB, pagosDB, loading } = useSelector((state) => state.pagos);

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    dispatch(facturasporfechaProveedor(data))
  }

  //TODO:getdata
  const getFacturas = async () => {
    dispatch(facturasProveedor());
  }

  const getPayments = async (value) => {
    setInvoice(value);
    dispatch(pagosFactura(value));  
    setIsOpen(true);
  }

  const getDetails = async (value) => { 
    dispatch(detallesFactura(value.invoice_num));
    setShowDetails(!showDetails);
  }
  const onClearForm =  () => {
    getFacturas(); 
    reset();
  }
  const modalHide = () => {
    setIsOpen(false);
    setShowDetails(false);
    dispatch(resetpagosDB())
    dispatch(resetdetallesDB())
  }


  // const indexOfLastRecord = currentPage * recordsPerPage;
  // const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  // const currentRecords = facturasDB.slice(indexOfFirstRecord, indexOfLastRecord);
  // const nPages = Math.ceil(facturasDB.length / recordsPerPage)

  useEffect(() => {
    getFacturas();    
    // eslint-disable-next-line
  }, []);
  
  

  return (
    <>
      <Modal 
        handleClose={modalHide} 
        isOpen={isOpen} 
        header='PAYMENTS -' 
        invoiceNum={'INVOICE ' + invoice} 
      >
        {
          loading 
          ? (
            <div className="table-responsive overflow-auto">
              { pagosDB && (
                  <table className="table table-bordered table-striped table-hover table-head-fixed overflow-auto">
                    <thead className='overflow-auto border border-dark'>
                      <tr className='border border-dark'>
                        <th scope="col" className='border border-dark'>PAYMENT DATE</th>
                        <th scope="col" className='border border-dark'>PAYMENT AMOUNT</th>
                        <th scope="col" className='border border-dark'>INTERNAL BANK ACCOUNT</th>
                        <th scope="col" className='border border-dark'>OBSERVACIONES</th>
                      </tr>
                    </thead>
                    {
                      <tbody className='overflow-auto'>
                        { pagosDB?.map((rowData, index) => (                        
                              <tr
                                key={index}
                                onClick={()=> {
                                  if(rowData.description == null ) { 
                                    getDetails(rowData);
                                  };
                                }}
                                style={{ cursor: "pointer" }}
                                className={`${ rowData?.description
                                  ? 'border border-dark bg-cancel' 
                                  : 'pb-0 mt-0 mb-0 text-black text-break'}`}
                              >
                                <td className='border border-dark'>{rowData.payment_date}</td>
                                <td className='text-right border border-dark'>{formatCurrency(Number(rowData.payment_amount), 2)}</td>
                                <td className='border border-dark'>{rowData.internal_bank_account}</td>
                                <td className='border border-dark'>{rowData.description}</td>
                              </tr>
                          ))
                        }
                      </tbody>
                    }
                    <tfoot className='border border-dark'>
                      {
                        pagosDB?.map(r => Number(r?.remanente)) > 0 ? (
                          <tr className='border border-dark'>
                            <td className='font-weight-bold border border-dark'>REMANENTE</td>
                            <td colSpan="1" className='text-right border border-dark'>
                              {formatCurrency(pagosDB?.map(r => (r?.remanente && r?.description == null) ? r?.remanente : 0 ).reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue),2))}
                            </td>
                            <td className='border border-dark'>&nbsp;</td>
                          </tr>
                        ) : (<></>)
                      }
                      
                      <tr className='border border-dark'>
                        <td className='font-weight-bold border border-dark'>TOTAL</td>
                        <td colSpan="1" className='text-right border border-dark'>
                          {formatCurrency(pagosDB?.map(r => r?.payment_amount && r?.description == null ? r?.payment_amount : 0 ).reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue),2))}
                        </td>
                        <td className='border border-dark'>&nbsp;</td>
                      </tr>
                    </tfoot>
                  </table>
              )}
              {
                loading 
                ? showDetails && (<PaymentTableFacturas/>) 
                : (
                  <Loading  text="Cargando ..." /> 
                )
              }
            </div>
          ) : (
            <Loading  text="Cargando ..." /> 
          )           
        }
      </Modal>
      <div className='row'>
          <div className='col-12'>
            <div className="row my-2">
              <div className="col-sm-6">
                <h1 className="m-0">Facturas</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">Proveedores</li>
                  <li className="breadcrumb-item active">Facturas</li>
                </ol>
              </div>
            </div>
            <div className='mt-2'>              
              <form className='row col-12 col-md-12' onSubmit={handleSubmit(onSubmit)}>
                <div className='col-12 col-md-3'>
                  <div className="form-group">
                    <label>Fecha inicial</label>
                    <input 
                      type="date"  
                      className='form-control'  
                      {...register("initial_date")}
                    />
                    <p>{errors.initial_date?.message}</p>
                  </div>
                </div>
                <div className='col-12 col-md-3'>
                  <div className="form-group">
                    <label>Fecha final</label>
                    <input 
                      type="date"  
                      className='form-control'  
                      {...register("end_date")}
                    />
                    <p>{errors.end_date?.message}</p>
                  </div>
                </div>
                <div className='col-12 col-md-2'>
                  <div className="form-group">
                    <label>Numero factura</label>
                    <input 
                      type="text"  
                      className='form-control'  
                      placeholder='Numero factura'
                      {...register("invoice_num")}
                    />
                    <p>{errors.invoice_num?.message}</p>
                  </div>
                </div>
                <div className='col-12 col-md-2 pt-4'>
                  <button 
                    type="submit" 
                    className='btn btn-danger btn-block font-weight-bold mt-2'>
                    Buscar
                  </button>
                </div>
                <div className='col-12 col-md-2 pt-4'>
                  <button 
                    type="button" 
                    className='btn btn-secondary btn-block font-weight-bold mt-2'
                    onClick={onClearForm}
                  >
                    Limpiar
                  </button>
                </div>
              </form>
            </div>
            <hr />
            <div className='mt-4 px-2' >  
               <div className="row">
                {loading ? ( 
                    <>
                      {
                        facturasDB.length > 0 
                        ? facturasDB?.map((r, index) => 
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4" key={index}>
                          <CardInvoice item={r} getPayment={getPayments} />
                        </div>                   
                      ) : (
                        <p>                          
                          No hay datos
                          </p>
                      ) }                    
                    </>
                  ) : (
                    <Loading  text="Cargando ..." /> 
                  )
                } 
               </div>   
            </div>
          </div>
      </div>
    </>
  )
}

export default Facturas;