import React from "react";
import { useSelector } from "react-redux";
import { rowsAsignColor } from '../../../utils/rowsAsignColor';

//anidacion
export const TableModel = ({ index, data }) => {
  const { tablaAlertsModel } = useSelector((state) => state.alerts);

  let cant = tablaAlertsModel[index]?.length || 0;

  return (
    <div  style={{fontFamily: "Arial Narrow"}}>
      <div className="col-12">
        {!!cant && (
          <table
            className="table table-bordered  my-table alineacion tamanioLetra" id="internaltable"
          >
            <thead>
              <tr>
                {Object.keys(tablaAlertsModel[index][0])?.map((column, index) => (
                  <th key={`tbl_model_th_${index}`} className="titulos" style={{ whiteSpace: 'pre', wordWrap: 'break-word', lineHeight: '1.2', verticalAlign: 'middle',fontFamily: "Arial Narrow" }}>
                    {column}
                  </th>
                )
                )}
              </tr>
            </thead>
            {<tbody>
                {tablaAlertsModel[index].map((data, indice) => (
                  <React.Fragment key={indice}>
                    <tr style={{ cursor: "pointer",fontFamily: "Arial Narrow" }}>
                      {Object.keys(tablaAlertsModel[index][indice]).map((column, colIndex) => (
                        <td key={colIndex} style={rowsAsignColor(column, data[column])}>
                          {data[column] === "0" || data[column] === null  ? '-' : 
                            (column === 'WOS -4\n|QTY|' || column === 'WOS +8\n|QTY|') ? 
                            parseFloat(data[column]).toFixed(1) : 
                            (data[column]< 0 ? data[column].toString().replace('-',''): data[column])
                          }
                        </td>
                      ))}
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>

            }
          </table>
        )
        }
      </div>
    </div>
  )

}
