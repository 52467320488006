import { createSlice } from "@reduxjs/toolkit";

export const pagosSlice = createSlice({
    name: 'pagos',
    initialState: {
        facturasDB: [],
        pagosDB: [],
        pagosProveedorDB: [],
        facturasDetails: {},
        detallesDB: [],
        loading: false,
    },
    reducers: {
        setFacturas: (state, { payload }) => {
            state.facturasDB = payload;
        },
        setPagos: (state, { payload }) => {
            state.pagosDB = payload;
        },
        setPagosProveedor: (state, { payload }) => {
            state.pagosProveedorDB = payload;
        },
        setDetalles: (state, { payload }) => {
            state.detallesDB = payload;
        },
        setLoading: (state, { payload }) => {
            state.loading = payload;
        },
        setFacturasDetails: (state, { payload }) => {
            state.facturasDetails = payload;
        },
        resetpagosDB: (state) => {
            state.pagosDB = []
        },
        resetdetallesDB: (state) => {
            state.detallesDB = []
        },  
        resetfacturasDB: (state) => {
            state.facturasDB= []
        },  
        
    },
});

export const {
    setFacturas,
    setFacturasDetails,
    setPagos,
    setPagosProveedor,
    setDetalles,
    setLoading,
    resetpagosDB,
    resetdetallesDB,
    resetfacturasDB
} = pagosSlice.actions;
