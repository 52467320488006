import { createSlice } from "@reduxjs/toolkit";

export const alertsSlice = createSlice({
  name: "alerts",
  initialState: {
    channelAlerts: [],
    dealerAlerts: [],
    pgAlerts: [],
    weeks: [],
    invDistr: [],
    tablaAlerts1: {
      data: [],
    },
    tablaPg2:[],
    tablaAlerts2: [] ,
    filtroAlertsBI: [],
    graficaPKAM: {
      data: [],
    },
    
    graficaAlerts: [],
    tableAlersInd: {},
    tablaAlertsModel: {},
    filtrosAlerts: {},
  },
  reducers: {
    setChannelAlerts: (state, { payload }) => {
      state.channelAlerts = payload;
    },
    setDealerAlerts: (state, { payload }) => {
      state.dealerAlerts = payload;
    },
    setPgAlerts: (state, { payload }) => {
      state.pgAlerts = payload;
    },
    setWeeks: (state, { payload }) => {
      state.weeks = payload;
    },
    setInvDistr: (state, { payload }) => {
      state.invDistr = payload;
    },
   
    setTablaAlerts1: (state, { payload }) => {
      state.tablaAlerts1 = payload;
    },
    setTablaPg2: (state, { payload }) => {
      state.tablaPg2 = payload;
    },
    setTablaAlerts2: (state, { payload }) => {
      state.tablaAlerts2 = payload;
    },
    setfiltroAlertsBI: (state, { payload }) => {
      state.filtroAlertsBI = payload;
    },
    setfiltroGraficaPKAM: (state, { payload }) => {
      state.graficaPKAM = payload;
    },
    setfiltroGraficaAlerts: (state, { payload }) => {
      state.graficaAlerts = payload;
    },
    setfiltrosAlerts: (state, { payload }) => {
      state.filtrosAlerts = payload;
    },
    setTableAlersInd: (state, { payload }) => {
      state.tableAlersInd =  payload;
    },
    setTableAlersModel: (state, { payload }) => {
      state.tablaAlertsModel =  payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  setChannelAlerts,
  setDealerAlerts,
  setPgAlerts,
  setWeeks,
  setInvDistr,
  setTablaAlerts1,
  setTablaPg2,
  setfiltroAlertsBI,
  setfiltroGraficaPKAM,
  setfiltroGraficaAlerts,
  setfiltrosAlerts,
  setfiltrosAlertsBI,
  setTablaAlerts2,
  setTableAlersInd,
  setTableAlersModel
} = alertsSlice.actions;
