import { createSlice } from "@reduxjs/toolkit"

export const wosSlice = createSlice({
  name: "bi_wos",
  initialState: {
    yearSelected:[], // Tabs
    yearDefault:[],
    channel: [], 
    permissionKams:[],
    dealerTable: ['ALL DEALER'],
    channelSelectedMJ: [],
    dealer: [],
    dealerSelected:[],
    dealerSelectedMJ: [],
    isLoading: false,
    isLoadingAllDealers: false,
    isLoadingDownDealersTable: false,
    filter_bi: {},
    filters: {},
    pg: [],
    pg_tool: [],
    pg2:[],
    status: [],
    ar:[],
    km: ["SI", "NO"],
    year:[],
    regional:[],
    majorCostmr:[],
    table_cpsi:{
      data: []
    },
    titulosFirstTable: [
      "P |PM|",
      "P |KAM|",
      "P |Y-1|",
      "S |R+F|",
      "S |Y-1|",
      "S |R+F| USD",
      "S |Y-1| USD",
      "USD ∆ VS Y-1",
      "S |R+F| COP",
      "S |Y-1| COP",
      "COP ∆ VS Y-1",
      "S FCST W-1",
      "AR% FCST W-1",
      "SELLABLE INV",
      "DISPLAY INV",
      "WOS-4 |QTY|",
      "WOS+8 |QTY|",
    ],
    table_MeasureGral:{
      data: []
    },
    table_cpsi2Gral:{
      data: []
    },
    table_NewCPSI:{},
    table_NewCPSIView:{
      data: []
    },
    table_NewMeasure:{
      data: []
    },
    table_NewCpsiFilterDealer:{
      data: []
    },
    tableCpsiDealerWeeks: {},
    tableCpsiWeeks:{}
  },
  reducers: {
    setChannel: (state, { payload }) => {
      state.channel = payload
    },
    setDealer: (state, { payload }) => {
      state.dealer = payload
    },
    setPg: (state, { payload }) => {
      state.pg = payload
    },
    setPg_Tool: (state, { payload }) => {
      state.pg_tool = payload
    },
    setPg_2: (state, { payload }) => {
      state.pg2 = payload
    },
    setStatus: (state, { payload }) => {
      state.status = payload
    },
    setAprovedRef: (state, { payload }) => {
      state.ar = payload
    },
    setKeyModels: (state, { payload }) => {
      state.km = payload
    },
    setYear: (state, { payload }) => {
      state.year = payload
    },
    setRegional: (state, { payload }) => {
      state.regional = payload
    },
    setMajorCostmr: (state, { payload }) => {
      state.majorCostmr = payload
    },
    changeLoading: (state, { payload }) => {
      state.isLoading = payload
    },
    changingLoadingAllDealers: (state, { payload }) => {
      state.isLoadingAllDealers = payload
    },
    changingLoadingDownDealersTable: (state, { payload }) => {
      state.isLoadingDownDealersTable = payload
    },
    setFilterWos: (state, { payload }) => {
      state.filters = payload
    },
    setChannelpsi: ( state, {payload}) => {
      state.table_cpsi = payload
    },
    setMeasureGeneral: ( state, {payload}) => {
      state.table_MeasureGral = payload
    },
    setTableCPSIGral: ( state, {payload}) => {
      state.table_cpsi2Gral = payload
    },
    setTableNewCPSI: ( state, {payload}) => {
      state.table_NewCPSI = payload
    },
    setTableNewCPSIView: ( state, {payload}) => {
      state.table_NewCPSIView = payload
    },
    setTableNewMeasure: ( state, {payload}) => {
      state.table_NewMeasure = payload
    },
    setTableCpsiFilterDealer: ( state, {payload}) => {
      state.table_NewCpsiFilterDealer = payload
    },
    setDealerSelected: ( state, {payload}) => {
      state.dealerSelected = payload
    },
    setStateGeneral: ( state , {payload} ) => {
       Object.assign(state, payload)
    },
    settitulosFirstTable: (state , {payload}) => {
      console.log('settitulosFirstTable', payload)
    },
    setDealerSelectedMJ: ( state, {payload}) => {
      state.dealerSelectedMJ = payload
    },
    setChannelSelectedMJ: ( state, {payload}) => {
      state.channelSelectedMJ = payload
    },
    setPermissionKams: ( state, { payload }) => {
      state.permissionKams = payload
    },
    setYearSelected: (state, { payload }) => {
      state.yearSelected = payload
    },
    setTableCpsiWeeks: (state, { payload }) => {
      state.tableCpsiWeeks = payload
    },
    setTableCpsiDealerWeeks: (state, { payload }) => {
      state.tableCpsiDealerWeeks = payload
    }
    
  },
})

// Action creators are generated for each case reducer function
export const {
  changeLoading,
  changingLoadingAllDealers,
  changingLoadingDownDealersTable,
  setFilterWos,
  setChannel,
  setDealer,
  setPg,
  setPg_Tool,
  setPg_2,
  setStatus,
  setAprovedRef,
  setKeyModels,
  setRegional,
  setMajorCostmr,
  setChannelpsi,
  setMeasureGeneral,
  setTableCPSIGral,
  setTableNewCPSI,
  setTableNewCPSIView,
  setTableNewMeasure,
  setTableCpsiFilterDealer,
  setDealerSelected,
  settitulosFirstTable,
  setStateGeneral,
  setDealerSelectedMJ,
  setChannelSelectedMJ,
  setPermissionKams,
  setYearSelected,
  setTableCpsiWeeks,
  setTableCpsiDealerWeeks,
} = wosSlice.actions
