import React from 'react';

export const Accordeon = ({children, text}) => {
  return (
    <div  >
      <div className="card-body">
        <div id="accordion">
        <div className="card card-light">
          <div className="card-header">
            <h4 className="card-title w-100">
              <a className="d-block w-100" data-toggle="collapse" href="#collapseOne">
                {text}
              </a>
            </h4>
          </div>
          <div id="collapseOne" className="collapse show" data-parent="#accordion">
            <div className="card-body">
              {children}
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};



  






