import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { allContacts } from "../../slices"

export const Contact = () => {
  const dispatch = useDispatch()
  const { contacs = []} = useSelector( (state) => state.user)
  // console.log(contacs)
  useEffect(() => {
    dispatch(allContacts())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return (
    <>
      <div className="card card-solid">
        <div className="card-body pb-0">
          <div className="row">
            { contacs.map( (info ,key) => {
            return (
              <div key={key} className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
                <div className="card bg-light d-flex flex-fill">
                  <div className="card-header text-muted border-bottom-0">
                    Digital Strategist
                  </div>
                  <div className="card-body pt-0">
                    <div className="row">
                      <div className="col-7">
                        <h2 className="lead"><b>{ ` ${info.first_name} ${info.last_name}` }</b></h2>
                        <p className="text-muted text-sm"><b>About: </b> Web Designer / UX / Graphic Artist / Coffee Lover <b>({info.email})</b> </p>
                        <ul className="ml-4 mb-0 fa-ul text-muted">
                          <li className="small"><span className="fa-li"><i className="fas fa-lg fa-building"></i></span> Address: Demo Street 123, Demo City 04312, NJ</li>
                          <li className="small"><span className="fa-li"><i className="fas fa-lg fa-phone"></i></span> Phone #: + 800 - 12 12 23 52</li>
                        </ul>
                      </div>
                      <div className="col-5 text-center">
                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYu-T3U1TvtIfeXInhI8BTbvXb6PoEHrJQfO56kp_XIw&s" alt="user-avatar" className="img-circle img-fluid"/>
                      </div>
                    </div>
                    {/* <div className="text-right">
                      <a href="#" className="btn btn-sm bg-teal">
                        <i className="fas fa-comments"></i>
                      </a>
                      <a href="#" className="btn btn-sm btn-primary">
                        <i className="fas fa-user"></i> View Profile
                      </a>
                    </div> */}
                  </div>
                  <div className="card-footer">
                    {/* <div className="text-right">
                      <a href="#" className="btn btn-sm bg-teal">
                        <i className="fas fa-comments"></i>
                      </a>
                      <a href="#" className="btn btn-sm btn-primary">
                        <i className="fas fa-user"></i> View Profile
                      </a>
                    </div> */}
                  </div>
                </div>
              </div>
            )}
            )}
          </div>
      </div>
      </div>
    </>
  )
}