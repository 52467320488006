import React, { useEffect, useState } from "react"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"


// Importar acciones y funciones asíncronas de Redux
import {
  cpsGeneral,
  startChannel,
  cpsMeasure,
  cpsTablaGeneral,
  dataNewCPSI,
  cpsNewMeasure,
  dataNewCPSIFilterDealer,
  onDealerChannelsDefault,
  onDealerMCDefault,
  resetDealerWeeks,
  resetWeeks
} from "../../slices/bi/thunks"
import {
  changingLoadingAllDealers, setYearSelected
  // setDealerSelected,
} from "../../slices/bi/wosSlice"

// Definir el componente funcional principal
export const Filtros = () => {
 const {
    channel,
    dealer,
    pg: PG,
    pg2: PG2,
    status: Status,
    ar: aprovedReference,
    pg_tool: PG_Tool,
    year,
    regional,
    majorCostmr, // * Major Costumer
    permissionKams,
    yearDefault
  } = useSelector((state) => state.bi_wos)

  //  inicializar variables de estado
  const [mcdefaultFilters, setMCDefaultFilters] = useState([])
  const [channelDefaultFilters, setchannelDefaultFilters] = useState([])
  const [dealerDefault, setdealerDefault] = useState([])
  const [filters, setFilters] = useState({})
  const dispatch = useDispatch()
  // captura el año seleccionado y lo dispara a un estado 
  const onSelectedanio = (values = []) => {
    const anios = values.map(({ value }) => value)
    dispatch(setYearSelected(anios))
  }
  // useEffect para despachar acciones Redux de los Dealers y los PG llenarlas con el store cuando esten vacías
  useEffect(() => {
    let dealers = { DEALER: dealer.map(({ value }) => value) }
    let pg = { PG: PG.map(({ value }) => value) }
    let status = { STATUS: Status.map(({ value }) => value) }
    //let pg2 = { PG2: PG2.map(({ value }) => value) }

    setFilters((state) => ({
      ...state,
      ...dealers,
      ...pg,
      ...status,
      //...pg2,
      
    }
    ))
  }, [dealer, PG, Status])

  const updateFilters = (name, values = []) => {
    const data = values.map(({ value }) => value)
    // Objeto de valores iniciales según el filtro
    const initialValuesMap = {
      CHANNEL: 'HYPER',
      DEALER: dealer.map(({ value }) => value),
      PG: PG.map(({ value }) => value),
      STATUS: Status.map(({ value }) => value),
      //PG2: PG2.map(({ value }) => value)

    }
    // Restablecer los datos a los valores iniciales según el filtro.
    setFilters((state) => ({
      ...state,
      [name]: (data.length === 0) ? initialValuesMap[name] : data
    }))
   
  }

  const updateFiltersMJ = (name, values) => {
    const channelsOnMJ = values.map(({ value }) => value)
    let filterValueChannel = {
      [name]: channelsOnMJ,
    }

    //Default Values Dealer on Major Costumer.
    if (!channelsOnMJ.length) {
      setdealerDefault([])
    } else {
      const ondefaultDealerMC = async () => {
        const {
          DEALER = []
        } = permissionKams

        const { data } = await onDealerMCDefault(
          {
            ...filterValueChannel,
            DEALER
          }
        )
        setdealerDefault(data)
        setFilters((state) => ({
          ...state,
          DEALER,
        }))
      }
      ondefaultDealerMC()
      // limpiamos channel
      setchannelDefaultFilters([])
    }
    setFilters((state) => ({
      ...state,
      ...filterValueChannel
    }))
    setMCDefaultFilters(values)
  }

  // * Update con base a Channel
  const updateFiltersChannel = (name, values = []) => {
    const dealerOnchannels = values.map(({ value }) => value)
    let filterValueChannel = {
      [name]: dealerOnchannels,
    }

    //Default Values Dealer.
    if (!dealerOnchannels.length) {
      // limpiar dealers
      setdealerDefault([])
    } else {
      const ondefaultDealer = async () => {
        // Extrac key DEALER
        const { DEALER = [] } = permissionKams
        const { data } = await onDealerChannelsDefault({
          ...filterValueChannel,
          DEALER
        })

        setdealerDefault(data)
        setFilters((state) => ({
          ...state,
          ...{
            // eslint-disable-next-line no-useless-computed-key
            ["DEALER"]: data.map(({ value }) => value),
          },
        }))
      }
      ondefaultDealer()
    }
    setFilters((state) => ({
      ...state,
      ...filterValueChannel,
    }))

    setchannelDefaultFilters(values)
    // limpiamos Major.
    setMCDefaultFilters([])
  }


  const updateFiltersDealer = (name, values) => {
    const dealerOnchannels = values.map(({ value }) => value)
    let filterValueDealer = {
      [name]: dealerOnchannels,
    }
    // cambiar Estados Default Dealer.
    setdealerDefault(values)

    setFilters((state) => ({
      ...state,
      ...filterValueDealer,
    }))
  }

  const SelectDealer = ({ defaultValue = [] }) => {
    return (
      <div>
        <label>Dealer</label>
        <Select
          id="dealer"
          label="Dealer"
          placeholder="Filtro de Dealer"
          options={dealer}
          name="DEALER"
          defaultValue={defaultValue}
          onChange={(e) => {
            updateFiltersDealer("DEALER", e)
          }}
          isMulti
          isClearable={true}
        />
      </div>
    )
  }

  const SelectChannel = () => {
    return (
      <Select
        id="channel"
        label="Channel"
        placeholder="Filtro de Channel"
        options={channel}
        isMulti
        maxSelections="5"
        defaultValue={channelDefaultFilters}
        onChange={(e) => {
          updateFiltersChannel("CHANNEL", e)
        }}
      />
    )
  }

  const SelectMajor = () => {
    return (
      <Select
        id="majorCostumer"
        options={majorCostmr}
        label="Major Costumer"
        placeholder=" Filtro de Major Costumer"
        name="FOCO"
        defaultValue={mcdefaultFilters}
        onChange={(e) => {
          updateFiltersMJ("FOCO", e)
        }}
        isMulti
      />
    )
  }
  const enviarSeleccion = (e) => {
    e.preventDefault()
    dispatch(changingLoadingAllDealers(false))
    dispatch(cpsMeasure(filters))
    dispatch(cpsTablaGeneral(filters))
    dispatch(cpsNewMeasure(filters))
    dispatch(dataNewCPSI(filters))
    dispatch(cpsGeneral(filters))//pinta la data de la grafica
    dispatch(dataNewCPSIFilterDealer(filters),
      (() => {
        $('#collapseOne').collapse('hide') // eslint-disable-line
      })()
    )
    dispatch(resetDealerWeeks())
    dispatch(resetWeeks())
  }
  // useEffect para despachar acciones Redux de filtros y datos de tabla al montar el componente
  useEffect(() => {
    dispatch(startChannel())
    dispatch(cpsGeneral(filters))
    dispatch(cpsNewMeasure(filters))
    dispatch(dataNewCPSI(filters))
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  

  if (!yearDefault[0]) return <></>
  return (
    <>
      {/* <div className="container"> */}
      <div className="row" style={{ fontFamily: "Arial Narrow" }}>
        <div className="col">
          <label>Channel</label>
          <SelectChannel />
        </div>
        <div className="col">
          <SelectDealer defaultValue={dealerDefault} />
        </div>
        <div className="col">
          <label>Major Costumer</label>
          <SelectMajor />
        </div>
        <div className="col">
          <label>Aproved Reference</label>
          <Select
            id="aprovedReference"
            options={aprovedReference}
            label="Aproved Reference"
            placeholder="Filtro de Aproved Reference"
            name="APPROVED_REFERENCE"
            isMulti
            onChange={(e) => {
              updateFilters("APPROVED_REFERENCE", e)
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label>PG</label>
          <Select
            id="pg"
            options={PG}
            label="Pg"
            placeholder="PG"
            name="PG"
            isMulti
            onChange={(e) => {
              updateFilters("PG", e)
            }}
          />
        </div>
        <div className="col">
          <label>PG Tool</label>
          <Select
            id="pg_tool"
            options={PG_Tool}
            label="Pg Tool"
            placeholder=" Filtro de PG Tool"
            name="PG_TOOL"
            isMulti
            onChange={(e) => {
              updateFilters("PG_TOOL", e)
            }}
          />
        </div>

        <div className="col">
          <label>PG2</label>
          <Select
            id="pg2"
            options={PG2}
            label="Pg2"
            isMulti
            placeholder="Filtro de PG 2"
            name="PG2"
            onChange={(e) => {
              updateFilters("PG2", e)
            }}
          />
        </div>
        <div className="col">
          <label>Regional</label>
          <Select
            id="regional"
            options={regional}
            label="Regional"
            placeholder="Filtro de Regional"
            name="REGIONAL"
            isMulti
            onChange={(e) => {
              updateFilters("REGIONAL", e)
            }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-3">
          <label>Status</label>
          <Select
            id="status"
            options={Status}
            label="Status"
            isMulti
            placeholder="Filtro de Status"
            name="STATUS"
            onChange={(e) => {
              updateFilters("STATUS", e)
            }}
          />
        </div>
        <div className="col-3">
          <label>Year</label>
          <Select
            id="YEAR_1"
            label="Year"
            placeholder="Filtro de Year"
            options={year}
            name="YEAR_1"
            isMulti
            onChange={(e) => {
              updateFilters("YEAR_1", e)
              onSelectedanio(e)
            }}
          />

        </div>
        <div className="col pt-2">
          <button
            type="button"
            id="enviar_dato"
            name="enviar_dato"
            className="btn btn-danger raise mt-4"
            // style={{
            //   cursor: "pointer",
            //   marginTop: "20px",
            //   backgroundColor: "#dc3545",
            // }}
            onClick={enviarSeleccion}
          >
            Enviar Datos
          </button>
        </div>
      </div>
    </>
  )
}
