import React, {  useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { tableCpsiDealerWeek } from '../../../../../slices/bi/thunks'
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact"

//TODO: Importar funciones y componentes necesarios
import {
  getColorResoForc,
  getColorsCpsi,
  getColorsWhite,
  getColorWos,
  getColorsS,
  getColorsUSD,
  getColorsCOP,
  getColorPercentCOP,
  getColorPercentUSD,
  getColorPercentWhite,

} from '../../../../../utils/colorsFunctions'
import { rowsAsignColor } from '../../../../../utils/rowsAsignColor'


export const TablaCpsiForDealer = () => {
  const tablaNewCPSI = useSelector((state) => state.bi_wos.table_NewCPSI)
  const tableCpsiDealerWeeks = useSelector((state) => state.bi_wos.tableCpsiDealerWeeks)
  const { filters } = useSelector((state) => state.bi_wos)
  const { data } = useSelector((state) => state.bi_wos.table_NewCpsiFilterDealer)
  const [expandedData, setExpandedData] = useState({})
  const dispatch = useDispatch()
  //* variables de Cpsi All para las cabeceras
  const mes = tablaNewCPSI.MES
  const weeks = tablaNewCPSI.Measure
  const ResultForcast = tablaNewCPSI.WEEK_TIPO
  const years = tablaNewCPSI.YEAR_1

  const handleClick = (index, header) => {
    const params = {
      YEAR_1: years[index],
      MES: header
    }

    dispatch(tableCpsiDealerWeek(params, filters, index))
    setExpandedData({
      ...expandedData,
      [index]: !expandedData[index],
    })
  }

  let rows = [];
// Recorrer cada objeto del store en data
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const store = data[key]
      
      // * Se Crea un objeto para cada medida
      const totalPM = {
        key,
        data: store["P |PM|"],
        getColor: (value) => getColorsWhite(value),
      };

      const totalPKAM = {
        key,
        data: store["P |KAM|"],
        getColor: (value) => getColorsWhite(value),
      };
      const totalPY_1 = {
        key,
        data: store["P |Y-1|"],
        getColor: (value) => getColorsWhite(value),
      };
      const totalSRF = {
        key,
        data: store["S |R+F|"],
        getColor: (value) => getColorsS(value),
      };
      const totalSY_1 = {
        key,
        data: store["S |Y-1|"],
        getColor: (value) => getColorsS(value),
      };
      const totalSRFUSD = {
        key,
        data: store["S |R+F| USD"],
        getColor: (value) => getColorsUSD(value),
      };
      const totalSY_1USD = {
        key,
        data: store["S |Y-1| USD"],
        getColor: (value) => getColorsUSD(value),
      };
      const totalUSDvsY_1 = {
        key,
        data: store["USD ∆ VS Y-1"],
        getColor: (value) => getColorPercentUSD(value),
      };
      const totalSRFCOP = {
        key,
        data: store["S |R+F| COP"],
        getColor: (value) => getColorsCOP(value),
      };
      const totalSY_1COP = {
        key,
        data: store["S |Y-1| COP"],
        getColor: (value) => getColorsCOP(value),
      };
      const totalCOPvsY_1 = {
        key,
        data: store["COP ∆ VS Y-1"],
        getColor: (value) => getColorPercentCOP(value),
      };
      const totalSFCSTw_1 = {
        key,
        data: store["S FCST W-1"],
        getColor: (value) => getColorsWhite(value),
      };
      const totalARFCSTw_1 = {
        key,
        data: store["AR% FCST W-1"],
        getColor: (value) => getColorPercentWhite(value),
      };
      const totalSellableInv = {
        key,
        data: store["SELLABLE INV"],
        getColor: (value) => getColorsWhite(value),
      };
      const totalDisplayInv = {
        key,
        data: store["DISPLAY INV"],
        getColor: (value) => getColorsWhite(value),
      };
      const totalWos_4QTY = {
        key,
        data: store["WOS-4 |QTY|"],
        getColor: (value) => getColorWos(value),
      };
      const totalWosM8QTY = {
        key,
        data: store["WOS+8 |QTY|"],
        getColor: (value) => getColorWos(value),
      };
      // * Se agrega cada objeto al arreglo rows utilizando spread
      rows = [
        ...rows,
        { ...totalPM },
        { ...totalPKAM },
        { ...totalPY_1 },
        { ...totalSRF },
        { ...totalSY_1 },
        { ...totalSRFUSD },
        { ...totalSY_1USD },
        { ...totalUSDvsY_1 },
        { ...totalSRFCOP },
        { ...totalSY_1COP },
        { ...totalCOPvsY_1 },
        { ...totalSFCSTw_1 },
        { ...totalARFCSTw_1 },
        { ...totalSellableInv },
        { ...totalDisplayInv },
        { ...totalWos_4QTY },
        { ...totalWosM8QTY },
      ];
    }
  }
  // * Valores de la primera Columna
  const titulosFirstColumn4 = data
    ? Object.keys(data).flatMap((key) => Object.keys(data[key]).filter(
      (llave) => llave !== "Measure" && llave !== "DEALER" && llave !== "WEEK_TIPO" && llave !== "WEEK_1" && llave !== "YEAR_1" && llave !== "title")
    ) : []

  if (Object.entries(data).length === 0) {
    return <></>
  }
  for (const key in data) {
    return (
      <div className="row table-wrapper bg-white">
        <MDBTable
          maxHeight="400px"
          scrollY
          className="my-table alineacion tamanioLetra"
          bordered
          small
          responsive={false}
          hover
          id='tbl_CpsiAllForDealer'
        >
          <MDBTableHead style={{ position: 'sticky', top: '0', zIndex: '1',backgroundColor: '#808080' }}>
            <tr style={{ fontFamily: "Arial Narrow", }}>
            <th style={{ position: "sticky", left: 0 }}></th>
              <th style={{ position: "sticky", left: 85 }} key="Measure" className="titulos"></th>
              {years.map((header, i) => (
                <React.Fragment key={i}>
                  {expandedData[i] && tableCpsiDealerWeeks[i] && Object.values(tableCpsiDealerWeeks[i])[0].YEAR_1.map((header, j) => (
                    <th key={j} style={
                      header === 2023 ? { color: '#008000', backgroundColor: '#FFFFFF' } : getColorsWhite(header)}>
                      {header}
                    </th>
                  ))
                  }
                  <th style={header === 2023 ? { color: '#008000', backgroundColor: '#FFFFFF' } : getColorsWhite(header)}>{header}</th>
                </React.Fragment>
              ))}
            </tr>
            <tr>
              <th style={{ position: "sticky", left: 0 }}></th>
              <th style={{ position: "sticky", left: 85 }} key="Measure" className="titulos"></th>
              {ResultForcast.map((header, i) => (
                <React.Fragment key={i}>
                   {expandedData[i] && tableCpsiDealerWeeks[i] && Object.values(tableCpsiDealerWeeks[i])[0].WEEK_TIPO.map((header, j) => (
                    <th key={j} style={getColorResoForc(header)}>
                      {header}
                    </th>
                  ))}
                  <th style={getColorResoForc(header)} >
                    {header}
                  </th>
                </React.Fragment>
              ))}
            </tr>
            <tr>
              <th style={{ position: "sticky", left: 0 }}></th>
              <th style={{ position: "sticky", left: 85 }} key="Measure" className="titulos"></th>
              {mes.map((header, i) => (
                <React.Fragment key={i}>
                    {expandedData[i] && tableCpsiDealerWeeks[i] && Object.values(tableCpsiDealerWeeks[i])[0].MES.map((header, j) => (
                    <th key={j} style={getColorsWhite(header)}>
                      {header}
                    </th>
                  ))}
                  <th style={getColorsCpsi(header)} onClick={() => handleClick(i, header)}>
                  <span style={{color: 'green'}}>&#43;</span>{header}
                  </th>
                </React.Fragment>
              ))}
            </tr>
            <tr>
              <th style={{ textAlign: "left", fontFamily: "Arial Narrow", fontWeight: "bold", position: "sticky", left: 0 }} key="Measure" className="titulos">MEASURE</th>
              <th style={{ textAlign: "left", fontFamily: "Arial Narrow", fontWeight: "bold", position: "sticky", left: 85 }}>DEALER</th>
              {weeks.map((header, i) => (
                <React.Fragment key={i}>
                   {expandedData[i] && tableCpsiDealerWeeks[i] && Object.values(tableCpsiDealerWeeks[i])[0].Measure.map((header, j) => (
                    <th key={j} style={getColorsWhite(header)} >
                      {header}
                    </th>
                  ))}
                  <th style={getColorsCpsi(header)}>
                    {header}
                  </th>
                </React.Fragment>
              ))}

            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {rows.map((row, i) => (
            <tr key={i} >
                <td style={{ textAlign: "left", fontFamily: "Arial Narrow", fontWeight: "bold", position: "sticky", left: 0 }} className="titulos">
                {titulosFirstColumn4[i]}
              </td>
              <td style={{ textAlign: "left", fontFamily: "Arial Narrow", fontWeight: "bold", position: "sticky", left: 85 }} className="titulos">
               {row.key}
              </td>
            
              {row.data.map((value, j) => {
                const title = titulosFirstColumn4[i]
                const val = value
                const processEnvColorUpArrow = process.env.REACT_APP_COLOR_UP_ARROW

                let style = rowsAsignColor(title, val)
                style = (val === null && (title === 'USD ∆ VS Y-1' || title === 'COP ∆ VS Y-1')) ?
                  {
                    ...style,
                    backgroundColor: title === 'USD ∆ VS Y-1' ? '#e2efda' : '#fce4d6',
                    color: processEnvColorUpArrow,
                    backgroundImage: `url('/dist/img/uparrow.png')`,
                    backgroundPosition: '10% 40%',
                    backgroundSize: '0.3vw',
                    backgroundRepeat: 'no-repeat'
                  } :
                  (val === null && title === 'AR% FCST W-1') ?
                    {
                      ...style,
                      color: '#C00000',
                      backgroundColor: '#FFF2CC'
                    } :
                    style

                  // Captura el valor del campo LLAVE
                  let campoValor = row.key
                return (
                  <React.Fragment key={j}>
                    {expandedData[j] && tableCpsiDealerWeeks[j] && Object.values(tableCpsiDealerWeeks[j][campoValor][title]).map((header, k) => {
                      const title = titulosFirstColumn4[i]
                          return <td key={k} style={
                            (header === null && (title === 'USD ∆ VS Y-1' || title === 'COP ∆ VS Y-1'))
                              ? {
                                ...rowsAsignColor(title, header),
                                backgroundColor: title === 'USD ∆ VS Y-1' ? '#e2efda' : '#fce4d6',
                                color: process.env.REACT_APP_COLOR_UP_ARROW,
                                backgroundImage: `url('/dist/img/uparrow.png')`,
                                backgroundPosition: '10% 40%',
                                backgroundSize: '0.3vw',
                                backgroundRepeat: 'no-repeat'
                              }
                              : (header === null && title === 'AR% FCST W-1')
                                ? { ...rowsAsignColor(title, header), color: '#C00000', backgroundColor: '#FFFFFF' }
                                : (title === 'P' || title === 'P |PM|' || title === 'P |KAM|' || title === 'P |Y-1|' ||
                                  title === 'S FCST W-1' || title === 'AR% FCST W-1' || title === 'SELLABLE INV' ||
                                  title === 'DISPLAY INV')
                                  ? { ...rowsAsignColor(title, header), backgroundColor: '#FFFFFF' }
                                  : rowsAsignColor(title, header)
                          }>
                            {(title === 'USD ∆ VS Y-1' || title === 'COP ∆ VS Y-1' || title === 'AR% FCST W-1')
                              ? (header === null) ? `${parseInt(0)}%`
                                : `${parseFloat(header).toFixed(0)}`.replace(/\D/, '') + '%'
                              : (header === 0 || header === '0' || header === null || header === '' || header === undefined)
                                ? '-'
                                : header}
                          </td>
                    })
                  }
                    {/* datos de la tabla padre */}
                    <td style={style}>
                      {(title === 'USD ∆ VS Y-1' || title === 'COP ∆ VS Y-1' || title === 'AR% FCST W-1') ?
                        (val === null) ? `${parseInt(0)}%` : `${parseFloat(val).toFixed(0)}`.replace(/\D/, '') + '%' :
                        (val === 0 || val === '0' || val === null || val === '' || val === undefined) ?
                          '-' : val}
                    </td>
                  </React.Fragment>
                )
              })}
            </tr>
            ))}
          </MDBTableBody>
        </MDBTable>
      </div>
    )
  }
}