import React from 'react';
import { toast } from "react-toastify";
import { formatCurrency } from '../../utils/formatCurrency';

export const CardInvoice = (props) => {
  const values = props.item;

  const style = {
    height: "45px", 
    width: "80px"
  }


  return (
    <div 
      className="card card-danger card-outline"
      onClick={() => {
          if (values.cases_icon === 0 ) {
            toast.info('Esta factura no cuenta con datos de pago');
          } 
          if(props.hasOwnProperty('getPayment') && (values.cases_icon === 1 || values.cases_icon === 2 )) {
            props.getPayment(values.invoice)
          } 
          if(props.hasOwnProperty('getDetails')) {
            props.getDetails(values.invoice)
         }
      } 
    }
      style={{ cursor: 'pointer' }}
    >
      <div className="card-body">
        <div className='row px-1'>
          <div className='col-12 col-md-12 col-lg-6 col-xl-4'>
            <div className="row">
              <h5  className="card-title font-weight-bold">INVOICE:   <br/> 
              <span className="font-weight-bold text-red text-break">
                {values.invoice}
              </span></h5>
              {
                values.sumpagos > 0 ? <p  className="card-subtitle font-weight-bold">PAYMENT AMOUNT:  <br/> <span className="font-weight-bold text-red">{formatCurrency( values.payment_amount )}</span></p> : ''
              } 
            </div>
          </div>

          <div className='col-12 col-md-12 col-lg-6 col-xl-4'>
            {values.date && (             
                <p  className='pt-1 pb-0 mb-0 text-break'>
                  <b>INVOICE <wbr />DATE:</b> <wbr/> {values.date}
                </p>
              )
              }       
              <hr className='pt-1 pb-1 mt-0 mb-0' /> 
            {values.payment_date && values.description == null
              ? (
                <p className='pb-0 mb-0 text-break'>
                  <b>PAYMENT <wbr />DATE:</b>   <wbr/>{values.payment_date}
                </p>
              ) : (
                <p className={`${ values?.cases_icon === 2
                  ? 'pb-0 mt-0 mb-0 text-red font-weight-bold text-break' 
                  : 'pb-0 mt-0 mb-0 text-black text-break'}`}
                >
                  <b>PAYMENT <wbr />SCHEDULE:</b> <wbr/>{values.fecha_siguiente}
                </p>
              )
            }   
            {/* {values.cases_icon === 2  && (
                <>
                  <hr className='pt-1 pb-1 mt-0 mb-0' />
                  <p className={`${ values?.cases_icon === 2
                    ? 'pb-0 mt-0 mb-0 text-black text-break' 
                    : 'pb-0 mt-0 mb-0 text-red font-weight-bold text-break'}`}>
                    <b>PAYMENT <wbr />SCHEDULE:</b>   <wbr/>{values.fecha_siguiente}
                  </p>
                </>
              )
            }*/}
          </div>        
          <div className='col-12 col-md-12 col-lg-4 col-xl-4 py-3 text-center'>            
          {
            values.cases_icon === 0 || values.description != null
              ? (
                <img 
                  src="/dist/img/zero-paid.png" 
                  alt="" 
                  style={{...style}}
                /> 
              ) : values.cases_icon === 2 || values.description != null 
              ? (
                <img 
                  src="/dist/img/mid-paid.png" 
                  alt="" 
                  style={{...style}}
                /> 
              ) : (
                <img 
                  src="/dist/img/full-paid.png" 
                  alt="" 
                  style={{...style}}
                /> 
              )
            }       
          </div>
          </div>
      </div>
    </div>
  );
};
  