import React from 'react';
import {  useSelector } from "react-redux";
import { formatCurrency } from '../../utils/formatCurrency';

export const PaymentTableFacturas = () => {
    const { detallesDB } = useSelector((state) => state.pagos);
    return (
        <>
        {detallesDB?.length > 0 && detallesDB ?(
            <>
                <hr className='p-0 m-0'/>
                <h4 className="mt-1 mb-2 modal-title font-weight-bold">PAYMENT DETAILS</h4>
                <div className="table-responsive">
                    <table className="table table-bordered table-striped table-hover table-head-fixed border border-dark">
                        <thead className='border border-dark'>
                            <tr className='border border-dark'>
                                <th className='border border-dark'>DR ACCOUNT NAME</th>
                                <th className='border border-dark'>LINE BASE AMOUNT</th>
                                <th className='border border-dark'>INVOICE DESCRIPTION</th>
                            </tr>
                        </thead>
                        <tbody className='border border-dark'>
                            {
                                detallesDB.map((r, index) => (
                                    <tr key={index} className='border border-dark'>
                                        <td className='border border-dark'>{r.account_desc}</td>
                                        <td className='text-right border border-dark'>{formatCurrency(r.line_base_amount)}</td>
                                        <td className='border border-dark'>{r.invoice_description}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                        <tfoot className='border border-dark'>
                        <tr className='border border-dark'>
                            <td className='font-weight-bold border border-dark'>TOTAL</td>
                            <td colSpan="1" className='text-right border border-dark'>
                            {formatCurrency(detallesDB?.map(r => r?.line_base_amount).reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue),0))}
                            </td>
                            <td className='border border-dark'>&nbsp;</td>
                        </tr>                                
                        </tfoot>
                    </table>
                </div>
            </> 
            ) : (
                <p>No hay datos</p>
            )
        }                 
        </>
    );
}