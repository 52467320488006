import { instanceAxiosPrivate } from "../../axios";
import { toast } from "react-toastify";
import { changePath, setMenu, setLoading } from "./navigationSlice";


export const asignPathStore = (link) => {
  return async (dispatch) => {
    dispatch(changePath(link))
    localStorage.setItem('lastPath',link)
  }
}


export const getMenu = () => {
  return async (dispatch, getState) => {    
    try {
      const xhr = new instanceAxiosPrivate()
      const { data } = await xhr.services.get(`/v1/menus/getmenus`)
      dispatch(setMenu(data))
      dispatch(setLoading(true))
    } catch (error) {
      const { response } = error;
      const { request, ...errorObject } = response;
      toast.error(errorObject.data.msg + ' se refrescara la pagina automaticamente');
    }
  }


}