/* eslint-disable no-useless-computed-key */
import React, {useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";

import { rowsAsignColor } from '../../../../utils/rowsAsignColor';
import {tablaPg2 as tableSlicePg2 } from "../../../../slices/bi/alertsThunks";

export const TablaPg2 = () => {
  const { tablaPg2, dealerAlerts,pgAlerts } = useSelector((state) => state.alerts);
  const dispatch = useDispatch();

  useEffect(() => {    
    let dealers = {
      'DEALER': dealerAlerts.map(({value}) => value)
    }
    let pg = {
      'PG': pgAlerts.map(({value}) => value)
    }
    dispatch(tableSlicePg2({ ...dealers, ...pg }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealerAlerts, pgAlerts])

  if (!tablaPg2[0]) return <></>
  // Renderiza la tabla HTML con los datos y los totales
  return (
    <div className="row table-wrapper">
      <div className="col-12">
        
        <table
          id="tbl_cpsi"
          className="table table-bordered table-hover my-table alineacion tamanioLetra"
          style={{fontFamily: "Arial Narrow" }}
        >
          <thead>
            <tr>
              {Object.keys(tablaPg2[0]).map((column) => (
                <th key={column} className="titulos" style={{ whiteSpace: 'pre', wordWrap: 'break-word', lineHeight: '1.2', verticalAlign: 'middle',fontFamily: "Arial Narrow" }}>
                  {column}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
              {tablaPg2.map((rowData, index) => (
                <tr key={index} style={index === tablaPg2.length - 1 ? { fontWeight: 'bold',fontFamily: "Arial Narrow" } : {fontFamily: "Arial Narrow"}}>
                  {            
                    Object.entries(rowData).map((column, indice) => (
                      <td key={indice} style={rowsAsignColor(column[0], column[1])}>
                        {column[1] ==="0" || column[1] === null  ? '-' : 
                          (column[0] === 'WOS -4\n|QTY|' || column[0] === 'WOS +8\n|QTY|') 
                          ? parseFloat(column[1]).toFixed(1) 
                          : (column[1] < 0 ? column[1].toString().replace('-', '') : column[1])
                        }
                      </td>              
                    ))
                  }
                </tr>  
              ))}
            </tbody>

        </table>
      </div>
    </div>
  );
}; 
