import React from 'react';
import { formatCurrency } from '../../utils/formatCurrency';


export const PaymentCard = (props) => {
  const values = props.item;

  const obs = values.description;
  
  return (
    <div 
      className="card card-danger card-outline" 
      onClick={() => {
        if (values.payment_num !== null && obs == null ) {
          props.getInvoice(values)
        }
      }
    } 
      style={{ cursor: 'pointer'}}
    >
      <div className={ obs ? 'card-body bg-cancel' : 'card-body' }>
        <div className="row">
          <div className="col-12">         
            <ul className='list-unstyled'>              
              <li><p className='font-weight-bold text-lg m-0'>Payment Date: <span className='font-weight-bold text-md text-red'>{values.payment_date}</span></p></li>
              <li>
                    <p className='font-weight-bold text-lg m-0'>Payment Amount: 
                      <span className='font-weight-bold text-md text-red'>
                        {formatCurrency(values.payment_amount)}
                      </span>
                    </p>
                  </li>
              {
                values.description && (
                  <>
                  <li><b>OBSERVACIONES:</b><br />
                    <p>{values.description}</p>
                  </li>
                  <li>
                    <b className='text-red'>RECHAZADO</b>
                  </li>
                  </>
                ) 
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
  