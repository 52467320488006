import React from "react";

// * contante que controla la pantalla completa al hacer click en el boton
const expandirPantalla = () => {
  if (document.fullscreenElement) {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  } else {
    const elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    }
  }
}

export const Headercomponent = ({
  className,
  nav_item1_Link,
  nav_item1_Text,
  nav_item2_Link,
  nav_item2_Text,
  nav_item3_Link,
  nav_item3_Text,
  role,
  target,
  text,
}) => {
  

  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light ">
      {/* Left navbar links */}
      <ul className="navbar-nav">
        <li className="nav-item px-2">
          <button  className="btn" data-widget="pushmenu">
            <i className="fas fa-bars text-danger" />
          </button>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <a href={nav_item1_Link} className="nav-link" target="">
            {nav_item1_Text}
          </a>
        </li>
      </ul>

      {/* Right navbar links */}
      <ul className="navbar-nav ml-auto">
        {/* Navbar Search */}
        {/* <li className="nav-item">
              <a
                className="nav-link"
                data-widget="navbar-search"
                href="#"
                role="button"
              >
                <i className="fas fa-search" />
              </a>
              <div className="navbar-search-block">
                <form className="form-inline">
                  <div className="input-group input-group-sm">
                    <input
                      className="form-control form-control-navbar"
                      type="search"
                      placeholder="Search"
                      aria-label="Search"
                    />
                    <div className="input-group-append">
                      <button className="btn btn-navbar" type="submit">
                        <i className="fas fa-search" />
                      </button>
                      <button
                        className="btn btn-navbar"
                        type="button"
                        data-widget="navbar-search"
                      >
                        <i className="fas fa-times" />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </li> 
    
            {/* Messages Dropdown Menu */}
        <li className="messages-menu dropdown">
          <a className="nav-link" data-toggle="dropdown" href="1">
            {/* <i class="far fa-comments"></i> */}
            {/* <span class="badge badge-danger navbar-badge">3</span> */}
          </a>
          <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            <a href="1" className="dropdown-item">
              {/* Message Start */}
              <div className="media">
                <img
                  // src={`${process.env.REACT_APP_HOST}/dist/img/Lg-logo.png`}
                  src="../../../public/dist/img/Lg-logo.png"
                  alt="User Avatar"
                  className="img-size-50 mr-3 img-circle"
                />
                <div className="media-body">
                  <h3 className="dropdown-item-title">
                    Brad Diesel
                    <span className="float-right text-sm text-danger">
                      <i className="fas fa-star" />
                    </span>
                  </h3>
                  <p className="text-sm">Call me whenever you can...</p>
                  <p className="text-sm text-muted">
                    <i className="far fa-clock mr-1" /> 4 Hours Ago
                  </p>
                </div>
              </div>

              {/* Message End */}
            </a>
            <div className="dropdown-divider" />
            <a href="1" className="dropdown-item">
              {/* Message Start */}
              <div className="media">
                <img
                  src="dist/img/user8-128x128.jpg"
                  alt="User Avatar"
                  className="img-size-50 img-circle mr-3"
                />
                <div className="media-body">
                  <h3 className="dropdown-item-title">
                    John Pierce
                    <span className="float-right text-sm text-muted">
                      <i className="fas fa-star" />
                    </span>
                  </h3>
                  <p className="text-sm">I got your message bro</p>
                  <p className="text-sm text-muted">
                    <i className="far fa-clock mr-1" /> 4 Hours Ago
                  </p>
                </div>
              </div>

              {/* Message End */}
            </a>
            <div className="dropdown-divider" />
            <a href="1" className="dropdown-item">
              {/* Message Start */}
              <div className="media">
                <img
                  src="dist/img/user3-128x128.jpg"
                  alt="User Avatar"
                  className="img-size-50 img-circle mr-3"
                />
                <div className="media-body">
                  <h3 className="dropdown-item-title">
                    Nora Silvester
                    <span className="float-right text-sm text-warning">
                      <i className="fas fa-star" />
                    </span>
                  </h3>
                  <p className="text-sm">The subject goes here</p>
                  <p className="text-sm text-muted">
                    <i className="far fa-clock mr-1" /> 4 Hours Ago
                  </p>
                </div>
              </div>

              {/* Message End */}
            </a>
            <div className="dropdown-divider" />
            <a href="1" className="dropdown-item dropdown-footer">
              See All Messages
            </a>
          </div>
        </li>

        {/* Notifications Dropdown Menu */}

        <li className="nav-item dropdown">
          <a className="nav-link" data-toggle="dropdown" href="1">
            {/* <i className="far fa-bell" /> */}
            {/* <span className="badge badge-warning navbar-badge">15</span> */}
          </a>
          <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            <span className="dropdown-item dropdown-header">
              15 Notifications
            </span>
            <div className="dropdown-divider" />
            <a href="1" className="dropdown-item">
              <i className="fas fa-envelope mr-2" /> 4 new messages
              <span className="float-right text-muted text-sm">3 mins</span>
            </a>
            <div className="dropdown-divider" />
            <a href="1" className="dropdown-item">
              <i className="fas fa-users mr-2" /> 8 friend requests
              <span className="float-right text-muted text-sm">12 hours</span>
            </a>
            <div className="dropdown-divider" />
            <a href="1" className="dropdown-item">
              <i className="fas fa-file mr-2" /> 3 new reports
              <span className="float-right text-muted text-sm">2 days</span>
            </a>
            <div className="dropdown-divider" />
            <a href="1" className="dropdown-item dropdown-footer">
              See All Notifications
            </a>
          </div>
        </li>

        {/* Boton de Full Screen */}
        <li className="nav-item px-2">
          <button
            className="btn"
            data-widget="fullscreen"
            onClick={expandirPantalla}
            title="Click aquí para expandir la pantalla"
          >
            <i className="fas fa-expand-arrows-alt text-danger" />
          </button>
        </li>

        <li className="nav-item">
          
        </li> 
      </ul>
    </nav>
  );
};
