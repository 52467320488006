import React, { useEffect } from 'react'
import { TituloCpsi } from './titulo'
import { HeaderMenus } from '../Header/Header'
import { Aside } from '../menu/Aside'
import { Footer } from '../footer/Footer'
import { Body } from './Body'
import { useDispatch } from "react-redux";
import { getMenu } from '../../slices/navigation/navigationThunks'


export const Content = ( {children} ) => {
  const dispatch = useDispatch()

  const solicitudMenu = async () => {
    dispatch(getMenu())
  }

  useEffect(() => {
    solicitudMenu();
    // eslint-disable-next-line
  }, []);

  return (
    <Body>
      <div className="wrapper" >

      <HeaderMenus/>
      <Aside/>
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <TituloCpsi/>
        <div className="content">
          {/*Se adiciono el siguiente div para cerrar la clase "content-wrapper"
          ya que se comento todo el contenido de la pagina para uso futuro*/}        
          <div className="container-fluid">
            {children}
          </div>
        </div>
      </div>
      <Footer />
      </div>
    </Body>
  )
}
