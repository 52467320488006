import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux"
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import {
  getColor,
  getColor2WosMeasure,
  getColorWhite,
  getColorResoForc,
} from "../../../../utils/colorsFunctions"
import { cpsNewMeasure  as cpsNewMeasureRes } from "../../../../slices/bi/thunks"
import { memo } from "react";

export const TablaMeasureGral2 = memo(() => {
  
  const [rows , setRows] = useState(
    Array(9).fill({ data: [], getColor: (value) => getColor2WosMeasure(value) })
  )
  const dispatch = useDispatch()
  const tablaMeasureGral2 = useSelector((state) => state.bi_wos.table_NewMeasure.data)
  const {dealer, pg,channel} = useSelector((state) => state.bi_wos)
  
  useEffect(() => {
    let dealers = {
      'DEALER': dealer.map(({value}) => value)
    }
    let pgcpsi = {
      'PG': pg.map(({value}) => value)
    }
    let CHANNEL = {
      'CHANNEL': channel.map(({value}) => value)
    }
    dispatch(cpsNewMeasureRes({ ...CHANNEL,...dealers, ...pgcpsi }))
     // eslint-disable-next-line 
  }, [dealer, pg, channel])
  
  // * Valores de la primera columna store
  const titulosFirstColumn = tablaMeasureGral2?.length
    ? Object.keys(tablaMeasureGral2[0]).filter(
        (key) => key !== "Measure" && key !== "WEEK_TIPO"
      )
    : [];

  // * Valores del forcast o result
  const ResultForcast = tablaMeasureGral2[0]?.WEEK_TIPO ?? [];

    useEffect(() => {
      //scope
      setRows([
        {
          //label: "P |PM|",
          //data: tablaMeasureGral2[0] && tablaMeasureGral2[0]["P |PM|"] ? tablaMeasureGral2[0]["P |PM|"] : [],
          data: tablaMeasureGral2[0]?.["WOS-4 |Y-1|"] ?? [],
          getColor: (value) => getColor2WosMeasure(value),
        },
        {
          //label: "P |Y-1|",
          //data: tablaMeasureGral2[0] && tablaMeasureGral2[0]["P |Y-1|"] ? tablaMeasureGral2[0]["P |Y-1|"] : [],
          data: tablaMeasureGral2[0]?.["WOS+8 |Y-1|"] ?? [],
          getColor: (value) => getColor2WosMeasure(value),
        },
        {
          //label: "P |KAM|",
          //data: tablaMeasureGral2[0] && tablaMeasureGral2[0]["P |KAM|"] ? tablaMeasureGral2[0]["P |KAM|"] : [],
          data: tablaMeasureGral2[0]?.["WOS-4"] ?? [],
          getColor: (value) => getColor2WosMeasure(value),
        },
        {
          //label: "S |R+F|",
          data: tablaMeasureGral2[0]?.["WOS+8"] ?? [],
          getColor: (value) => getColor2WosMeasure(value),
        },
        {
          //label: "S |Y-1|",
          data: tablaMeasureGral2[0]?.["FLOORING |Y-1|"] ?? [],
          getColor: (value) => getColorWhite(value),
        },
        {
          //label: "S|Y-1|USD|",
          data: tablaMeasureGral2[0]?.["WRR |Y-1|"] ?? [],
          getColor: (value) => getColorWhite(value),
        },
        {
          //label: "S|R+F|USD|",
          data: tablaMeasureGral2[0]?.["FLOORING |R+F|"] ?? [],
          getColor: (value) => getColorWhite(value),
        },
        {
          //label: "USD ∆ Vs Y-1",
          data: tablaMeasureGral2[0]?.["WRR |R+F|"] ?? [],
          getColor: (value) => getColorWhite(value),
        },
        {
          //label: "S|R+F|COP|",
          data: tablaMeasureGral2[0]?.["A/R% |FCST W-1|"] ?? [],
          getColor: (value) => getColor(value)
        },
      ])   
    },[tablaMeasureGral2])
    
    if (!tablaMeasureGral2[0]) return <></>
  return (  
    <div className="table-wrapper">
      <MDBTable
        className="my-table alineacion tamanioLetra"
        bordered
        small
        responsive={false}
        hover
        id='tbl_measure'
      >
        <MDBTableHead>
          <tr >
            <th className="titulos"></th>
            {ResultForcast.map((header, i) => (
              <th  key={i} style={getColorResoForc(header)}>
                {header}
              </th>
            ))}
          </tr>
          <tr style={{textAlign: "left",fontFamily: "Arial Narrow" }} >
            <th className="titulos">MEASURE</th>
            {tablaMeasureGral2[0]?.Measure.map((header, i) => (
                <th key={i}>{header}</th>
              ))}
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {rows.map((row, i) => (
            <tr key={i}>
              <td style={{textAlign: "left",fontFamily: "Arial Narrow" }} className="titulos">{titulosFirstColumn[i]}
              
              </td>
              {row.data.map((value, j) => (
                <td key={j} style={row.getColor(value)}>
                    {i === 8  ?  parseFloat(value).toFixed(0) + '%' 
                    : i === 5 && value === '0.0' ? '-' 
                    : i === 4 && value === 0 ? parseFloat(value).toFixed(1)
                    : i === 6 ?  parseFloat(value).toFixed(2)
                    : value}
                </td>
              ))}
            </tr>
          ))
          }
        
        </MDBTableBody>
      </MDBTable>
    </div>
  )
})
