import React from "react";
import PropTypes from "prop-types";


export const Footer =( {version, copyrigth}) => {
  const today = new Date();
  const year = today.getFullYear();

  return (
    <footer className="main-footer">
      <strong>
         {copyrigth} {year} <span className="text-danger">LG Portal </span>.
      </strong>
      All rights reserved.
      <div className="float-right d-none d-sm-inline-block">
        <b>Version</b> {version}
      </div>
    </footer>
  )
}

Footer.prototype = {
  version: PropTypes.string.isRequired,
  copyrigth: PropTypes.string.isRequired
}

Footer.defaultProps = {
  version: '1.0.0',
  copyrigth: `Copyright © `
}