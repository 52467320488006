import {
    getColorWos,
    getColorsWhite,
    getColorPercentWhite,
    getColorDealerAlerts,
    getColorsWhites,
    getBackground,
    getCellWithArrow,
    getSOW1,
    getR_F,
    getR_F1,
    getColorsUSD,
    getColorsS,
    getColorsCOP,
    getDeltas,
    getDeltas2,
    colorsCPSIimg,
    FCSTW1CPSIimg,
    getColorsCpsi,
    getColorCpsi, 
    getColorWhite,
    getColor
  } from "./colorsFunctions";
  
  export const rowsAsignColor = (column, value) => {
    const definitions = {
      "DEALER": () => {
        return getColorDealerAlerts(value);
      },
      "S FCST W-1": () => {
        return getColorsCpsi(value);
      },
      "S_FCST W-1": () => {
        return getColorsWhite(value);
      },
      "A/R%\n|FCST W-1|": () => {
        return getColorPercentWhite(value);
      },
      "SELLABLE INV": () => {
        return getColorsCpsi(value);
      },
      "SELLABLE_INV": () => {
        return getColorsWhite(value);
      },
      "PG": () => {
        return getColorDealerAlerts(value);
      },
      "COSTUMER": () => {
        return getColorDealerAlerts(value);
      },
      "PG2": () => {
        return getColorDealerAlerts(value);
      },
      "MODELO": () => {
        return getColorDealerAlerts(value);
      },
      "S_MONTH-1": () => {
        return getColorDealerAlerts(value);
      },
      "P R": () => {
        return getColorDealerAlerts(value);
      },
      "P KAM W+1": () => {
        return getColorDealerAlerts(value);
      },
      "S\n|AVG-4|": () => {
        return getColorsWhites(value);
      },
      "WOS-4 |QTY|": () => {
        return getColorWos(value);
      },
      "WOS-4_|QTY|": () => {
        return getColorWos(value);
      },
      "WOS+8 |QTY|": () => {
        return getColorWos(value);
      },
      "WOS+8_|QTY|": () => {
        return getColorWos(value);
      },
      "SO\n|W-1|": () => {
        return getSOW1(value);
      },
      "S\n|R+F_|": () => {
        return getR_F(value);
      },
      "S FCTS\n|W-1|": () => {
        return getR_F(value);
      },
      "A/R%\n|FCST W-1_|": () => {
        return getR_F1(value);
      },
      "A/R%\n|FCST W-4|": () => {
        return getColorPercentWhite(value);
      },
      "WOS -4\n|QTY|": () => {
        return getColorWos(value);
      },
      "WOS +8\n|QTY|": () => {
        return getColorWos(value);
      },
      "WOS-4 |AMT|": () => {
        return getColorWos(value);
      },
      "WOS+8 |AMT|": () => {
        return getColorWos(value);
      },
      "AR% FCST W-4": () => {
        return getColorPercentWhite(value);
      },
      "AR% FCST W-1": () => {
        return getColorCpsi(value);
      },
      "Progress %": () => {
        return getColorPercentWhite(value);
      },
      "2~4 W": () => {
        return getBackground(value);
      },
      "4~8 W": () => {
        return getBackground(value);
      },
      "8~12 W": () => {
        return getBackground(value);
      },
      "> 12 W": () => {
        return getBackground(value);
      },
      "WH\nInv %": () => {
        const cellStyle = getCellWithArrow(value); // Llama a la función y obtén el estilo
        return {
          ...cellStyle, // Agrega el estilo devuelto por getCellWithArrow
        };
      },
      "Store\nInv %": () => {
        const cellStyle = getCellWithArrow(value); // Llama a la función y obtén el estilo
        return {
          ...cellStyle, // Agrega el estilo devuelto por getCellWithArrow
        };
      },
      "P |PM|": () => {
        return getColorsCpsi(value);
      },
      "P_|PM|": () => {
        return getColorsWhite(value);
      },
      "P |KAM|": () => {
        return getColorsCpsi(value);
      },
      "P_|KAM|": () => {
        return getColorsWhite(value);
      },
      "P |Y-1|": () => {
        return getColorsCpsi(value);
      },
      "P_|Y-1|": () => {
        return getColorsWhite(value);
      },
      "S |R+F|": () => {
        return getColorsS(value);
      },
      "S_|R+F|": () => {
        return getColorsS(value);
      },
      "S |Y-1|": () => {
        return getColorsS(value);
      },
      "S_|Y-1|": () => {
        return getColorsS(value);
      },
      "S |R+F| USD": () => {
        return getColorsUSD(value);
      },
      "S_|R+F| USD": () => {
        return getColorsUSD(value);
      },
      "S |Y-1| USD": () => {
        return getColorsUSD(value);
      },
      "S_|Y-1| USD": () => {
        return getColorsUSD(value);
      },
      "USD ∆ VS Y-1": () => {
        return colorsCPSIimg(value);
      },
      "USD_∆ VS Y-1": () => {
        return colorsCPSIimg(value);
      },
      "S |R+F| COP": () => {
        return getColorsCOP(value);
      },
      "S_|R+F| COP": () => {
        return getColorsCOP(value);
      },
      "S |Y-1| COP": () => {
        return getColorsCOP(value);
      },
      "S_|Y-1| COP": () => {
        return getColorsCOP(value);
      },
      "COP ∆ VS Y-1": () => {
        return FCSTW1CPSIimg(value);
      },
      "COP_∆ VS Y-1": () => {
        return FCSTW1CPSIimg(value);
      },
      "DISPLAY INV": () => {
        return getColorsCpsi(value);
      },
      "DISPLAY_INV": () => {
        return getColorsWhite(value);
      },
      "Δ Vs\nAVG-4": () => {
        const cellStyle = getDeltas(value)
        return {
          ...cellStyle, 
        }
      },
      "Δ Vs\nW-1": () => {
        const cellStyle = getDeltas2(value)
        return {
          ...cellStyle, 
        }
      }
     
      
    };
  
    if (!definitions[column] || typeof definitions[column] !== "function") {
      return { backgroundColor: "#FFFFFF" };
    }
    return !value ? { backgroundColor: "#FFFFFF" } : definitions[column](value);
  };