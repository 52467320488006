import React from "react"
import { Route, Routes } from "react-router-dom"
import { Login } from "../components/user/Login"
import { PrivateRoutes } from "./PrivateRoutes"
import { BiRoutes } from "../components/BI/routes/BiRoutes"
import { PublicRoutes } from "./PublicRoutes"
import { UserRoutes } from "../components/user/routes/UserRoutes"
import ErrorBoundary from "../components/ErrorBoundary/ErrorBoundary"
import { PagosRoutes } from "../components/PAGOS/routes/PagosRoutes"

export const AppRoutes = () => {
  
  return(
    <Routes>
      {/* Rutas Publicas */}

      <Route 
        path="/" 
        element={
          <PublicRoutes>
            <Login />          
          </PublicRoutes>
        }      
        errorElement={<ErrorBoundary />}
      />
      {/* Rutas Privadas */}
      <Route path="/*" 
        element={
          <PrivateRoutes> 
            <BiRoutes/>
            <PagosRoutes/>
            <UserRoutes/>
          </PrivateRoutes>
        }
        errorElement={<ErrorBoundary />}
      />
    </Routes>
  )
}